/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { isMobile } from 'react-device-detect'
import { sanitizeUrl } from '@braintree/sanitize-url'
import {
	PageTitle,
	QAContainer,
	QuestionTitle,
	Question,
	QuestionAnswer,
	QuestionContainer,
	AnswerContainer,
	LineBreak,
	TextStyle3,
	TextStyle1,
	Row,
	AnswerContainerMobile,
} from './common'

function MobileAppIssue(): ReactElement {

	const [selectedOption, setSelectedOption] = useState('')

	const handleChange = (event: any) => {
		const selectedValue = event.target.value
		setSelectedOption(selectedValue)

		// Check the selected value and navigate to the target ID
		if (selectedValue === 'question1') {
			window.location.href = 'faq/#not-able-to-view-articles'
		} else if (selectedValue === 'question2') {
			window.location.href = 'faq/#existing-article-not-able-to-view'
		} else if (selectedValue === 'question3') {
			window.location.href = 'faq/#appstore-charge'
		} else if (selectedValue === 'question4') {
			window.location.href = 'faq/#unable-to-use-multiple-devices'
		} else if (selectedValue === 'question5') {
			window.location.href = 'faq/#current-subscription'
		} else if (selectedValue === 'question6') {
			window.location.href = 'faq/#restore-purchase'
		} else if (selectedValue === 'question7') {
			window.location.href = 'faq/#transfer-subscriptions'
		} else if (selectedValue === 'question8') {
			window.location.href = 'faq/#not-able-to-find-mobile-app'
		}
		// Add more conditions as needed
	}
	return (
		<div role="main">
			{isMobile ?
				<><PageTitle>Technical Issues</PageTitle>
					<QAContainer>

						<span className='jump-question'>Jump to question</span>
						<select value={selectedOption} onChange={(e) => handleChange(e)} className='jump-question-select component-design'>
							<option value=''>- Select a question -</option>
							<option value='question1'>I just bought a subscription through the app. Why am I not able to view articles and reviews?</option>
							<option value='question8'>Why am I not able to find the Robert Parker Wine Advocate app in the Apple Store / Play store?</option>
							<option value='question2'>I have an existing Robert Parker account. Why am I not able to view Wine Advocate articles on the app?</option>
							<option value='question3'>Why am I being double charged by the app store and Robertparker.com?</option>
							<option value='question4'>I have bought a personal subscription on the mobile app. Why am I unable to use it on multiple devices?</option>
							<option value='question5'>If you are on &quot;Current Subscriptions&quot; page with a message to inform you that your subscription is unlinked…</option>
							<option value='question7'>I bought my subscription on the app but want to change device platforms (from iOS to Android or vice versa). How do I transfer the subscription?</option>
						</select>
						<Row>
							<AnswerContainerMobile>
								<QuestionTitle>
									I just bought a subscription through the app. Why am I not able to view articles and reviews?
									<QuestionAnswer>
										<TextStyle1>If you have just purchased a subscription without an account:</TextStyle1>
										<LineBreak />
										<p>1. Tap on the &ldquo;Account&ldquo; tab</p>
										<p>2.  If you see a message that says &ldquo;Your subscription cycle will end on [DD MM YYYY]&ldquo; in the Current Subscription section: Your subscription has been activated. If you are not able to view articles and reviews, please contact us for further assistance.</p>

										<p>3. Or if you see the login page with 4 buttons: Your subscription has not been activated. Tap on &ldquo;Restore purchases&ldquo; to activate the subscription. You should see the message in Step 2 if the subscription is activated.</p>

										<p style={{ fontWeight: 600 }}>If you have logged into your account:</p>

										<p>1. Tap on the &quot;Account&quot; tab</p>
										<p>2. If you see a message that says &ldquo;Your subscription cycle will end on [DD MMM YYYY]&ldquo; in the Current Subscription section: Your subscription has been activated. If you are not able to view articles and reviews, please contact us for further assistance.</p>
										<div id='existing-article-not-able-to-view'></div>

										<p>3. Or if you see a message that says &quot;Your subscription expired on [DD MMM YYYY] Purchase a subscription to access Robert Parker Wine Advocate content&quot;: Tap on &quot;Restore purchases&quot; in the Accounts tab to update your subscription status.</p>

										<p>If this does not work, please try restarting the app. For users with iPhone devices that are iPhone 14 and above, there are some incompatibility issues with the current app and a new app is in the works. We apologize for the inconvenience caused. </p>

										<p>You can <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}
										>contact</a></TextStyle3> us if you need further assistance.</p>
										<LineBreak />
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									Why am I not able to find the Robert Parker Wine Advocate app in the Apple Store / Play store?
									<QuestionAnswer>
										<p>We are currently working on a revamp for the iOS app with improvements. There are currently no plans to update the Android app in the Play store. As the current version is outdated, new users will not be able to locate the app in the app store. We apologize for the inconvenience caused. </p>
										<p>You can still browse our website on your phone.</p>
										<div id='not-able-to-find-mobile-app'></div>
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I have an existing Robert Parker account. Why am I not able to view Wine Advocate articles on the app?
									<QuestionAnswer>
										Wine Advocate articles and tasting notes are open to personal and commercial subscribers only. Please ensure that you have logged into your account by doing the following:
										<LineBreak />
										<div id='appstore-charge'></div>

										<p>1. Open the Account tab on the bottom navigation bar</p>

										<p>2. Tap on “Log in” to log into the app with your email and password</p>

										<p>3. Check that your subscription is still valid and has not expired</p>

										<p>4. You should be able to view all content in The Wine Advocate and the wine pages</p>

										<p>If you have done all of the above and are still unable to view subscriber-only content, please contact us at <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}
										>support@robertparker.com</a></TextStyle3> with a screenshot of your Account tab. If you are using an iPhone device that is iPhone 14 and above, there are some incompatibility issues with the current app and a new app is in the works. We apologize for the inconvenience caused.</p>
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									Why am I being double charged by the app store and Robertparker.com?

									<QuestionAnswer>
										If you have an existing Robertparker.com subscription, we strongly recommend that you log into your account when first using the app. 
										<LineBreak />

										The in-app subscription is managed independently by the respective app stores (Apple and Google) and can only be canceled on your end.
										<LineBreak />
										You can cancel your in-app subscription by doing the following:

										<LineBreak />
										<TextStyle1>For Apple:</TextStyle1>
										<LineBreak />

										<p>1. <i>Open the Settings app.</i></p>

										<p>2. <i>Tap your name.</i></p>


										<p>3. <i>Tap Subscriptions. (If you don&apos;t see  &ldquo;Subscriptions, &ldquo; tap  &ldquo;iTunes & App Store &ldquo; instead. Then tap your Apple ID, tap View Apple ID, sign in, scroll down to Subscriptions, and tap Subscriptions.)</i></p>

										<p>4. <i>Tap the subscription that you want to manage.</i></p>


										<p>5. <i>Tap Cancel Subscription. If you don&apos;t see Cancel Subscription, the subscription is already canceled and won&apos;t renew.</i></p>
										<div id='unable-to-use-multiple-devices'></div>

										<LineBreak />
										<TextStyle1>For Google:</TextStyle1>
										<LineBreak />

										<p>1. <i>On your Android phone or tablet, open the Google Play Store.</i></p>

										<p>2. <i>Check if you&apos;re signed in to the correct Google Account.</i></p>

										<p>3. <i>Tap Menu {'>'} Subscriptions.</i></p>

										<p>4. <i>Select the subscription you want to cancel.</i> </p>

										<p>5. <i>Tap Cancel subscription.</i></p>

										<p>6. <i>Follow the instructions.</i></p>
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I have bought a personal subscription on the mobile app. Why am I unable to use it on multiple devices?
									<QuestionAnswer>
										Your subscription may not be linked to any account when you have purchased a subscription on your mobile. You can access your subscription on all mobile devices by linking your subscription to an account.
										<LineBreak />
										<TextStyle1>If you do not have an account:</TextStyle1>
										<LineBreak />

										<p>1. Tap on Account tab and tap on &quot;Create an account&quot; button.</p>

										<p>2. Fill in the form to register for an account.</p>

										<p>3. Tap on &quot;Register&quot; button.</p>
										<div id='current-subscription'></div>


										<p>4. Your subscription should be linked to your newly created account.</p>

										<LineBreak />
										<TextStyle1>If you already have an existing account:</TextStyle1>
										<LineBreak />
										<div id='restore-purchase'></div>


										<p>1. Tap on Account tab and tap on &quot;Log in&quot; button</p>

										<p>2. Enter your email and password.</p>

										<p>3. Tap on &quot;Log in&quot; button.</p>

										<p>4. Your subscription should be linked to your account.</p>
									</QuestionAnswer>
									<div id='transfer-subscriptions'></div>
								</QuestionTitle>

								<QuestionTitle>
									If you are on &quot;Current Subscriptions&quot; page with a message to inform you that your subscription is unlinked…
									<QuestionAnswer>

										<p>1. Tap on &quot;Log in to link subscriptions&quot; button</p>

										<p>2. Enter your email and password.</p>

										<p>3. Tap on &quot;Log in&quot; button.</p>

										<p>4. Your subscription should be linked to your account.</p>

									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I bought my subscription on the app but want to change device platforms (from iOS to Android or vice versa). How do I transfer the subscription?
									<QuestionAnswer>
										The in-app subscription is managed independently by their respective app stores (Apple or Google) and is tied to that account. Subscriptions cannot be transferred cross-platforms. You should cancel your current in-app subscription and buy a new subscription on your new device.
										<LineBreak />
										<TextStyle3><Link to="">Instructions to cancel your Apple subscription</Link></TextStyle3>
										<LineBreak />
										<TextStyle3><Link to="">Instructions to cancel your Google subscription</Link></TextStyle3>
										<LineBreak />
										If you have bought your subscription from the website, you can login to your account on any device.


									</QuestionAnswer>
								</QuestionTitle>

							</AnswerContainerMobile>
						</Row>
					</QAContainer>
				</>
				:
				<>
					<PageTitle>Mobile App Issues</PageTitle>
					<QAContainer>
						<Row>
							<QuestionContainer>
								<Question>
									<HashLink to='#not-able-to-view-articles'>I just bought a subscription through the app. Why am I not able to view articles and reviews?</HashLink>
								</Question>
								<Question>
									<HashLink to='#not-able-to-find-mobile-app'>Why am I not able to find the Robert Parker Wine Advocate app in the Apple Store / Play store?</HashLink>
								</Question>
								<Question>
									<HashLink to='#existing-article-not-able-to-view'>I have an existing Robert Parker account. Why am I not able to view Wine Advocate articles on the app?</HashLink>
								</Question>
								<Question>
									<HashLink to='#appstore-charge'>Why am I being double charged by the app store and Robertparker.com?</HashLink>
								</Question>
								<Question>
									<HashLink to='#unable-to-use-multiple-devices'>I have bought a personal subscription on the mobile app. Why am I unable to use it on multiple devices?</HashLink>
								</Question>
								<Question>
									<HashLink to='#current-subscription'>If you are on &quot;Current Subscriptions&quot; page with a message to inform you that your subscription is unlinked…</HashLink>
								</Question>
								<Question>
									<HashLink to='#transfer-subscriptions'>I bought my subscription on the app but want to change device platforms (from iOS to Android or vice versa). How do I transfer the subscription?</HashLink>
								</Question>
							</QuestionContainer>
							<AnswerContainer>

								<QuestionTitle>
									I just bought a subscription through the app. Why am I not able to view articles and reviews?
									<QuestionAnswer>
										<TextStyle1>If you have just purchased a subscription without an account:</TextStyle1>
										<LineBreak />
										<p>1. Tap on the &ldquo;Account&ldquo; tab</p>
										<p>2.  If you see a message that says &ldquo;Your subscription cycle will end on [DD MM YYYY]&ldquo; in the Current Subscription section: Your subscription has been activated. If you are not able to view articles and reviews, please contact us for further assistance.</p>

										<p>3. Or if you see the login page with 4 buttons: Your subscription has not been activated. Tap on &ldquo;Restore purchases&ldquo; to activate the subscription. You should see the message in Step 2 if the subscription is activated.</p>

										<p style={{ fontWeight: 600 }}>If you have logged into your account:</p>

										<p>1. Tap on the &quot;Account&quot; tab</p>
										<p>2. If you see a message that says &ldquo;Your subscription cycle will end on [DD MMM YYYY]&ldquo; in the Current Subscription section: Your subscription has been activated. If you are not able to view articles and reviews, please contact us for further assistance.</p>
										<div id='existing-article-not-able-to-view'></div>

										<p>3. Or if you see a message that says &quot;Your subscription expired on [DD MMM YYYY] Purchase a subscription to access Robert Parker Wine Advocate content&quot;: Tap on &quot;Restore purchases&quot; in the Accounts tab to update your subscription status.</p>

										<p>If this does not work, please try restarting the app. For users with iPhone devices that are iPhone 14 and above, there are some incompatibility issues with the current app and a new app is in the works. We apologize for the inconvenience caused. </p>

										<p>You can <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}
										>contact</a></TextStyle3> us if you need further assistance.</p>
										<LineBreak />
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									Why am I not able to find the Robert Parker Wine Advocate app in the Apple Store / Play store?
									<QuestionAnswer>
										<p>We are currently working on a revamp for the iOS app with improvements. There are currently no plans to update the Android app in the Play store. As the current version is outdated, new users will not be able to locate the app in the app store. We apologize for the inconvenience caused. </p>
										<p>You can still browse our website on your phone.</p>
										<div id='not-able-to-find-mobile-app'></div>
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I have an existing Robert Parker account. Why am I not able to view Wine Advocate articles on the app?
									<QuestionAnswer>
										Wine Advocate articles and tasting notes are open to personal and commercial subscribers only. Please ensure that you have logged into your account by doing the following:
										<LineBreak />
										<div id='appstore-charge'></div>

										<p>1. Open the Account tab on the bottom navigation bar</p>

										<p>2. Tap on “Log in” to log into the app with your email and password</p>

										<p>3. Check that your subscription is still valid and has not expired</p>

										<p>4. You should be able to view all content in The Wine Advocate and the wine pages</p>

										<p>If you have done all of the above and are still unable to view subscriber-only content, please contact us at <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}
										>support@robertparker.com</a></TextStyle3> with a screenshot of your Account tab. If you are using an iPhone device that is iPhone 14 and above, there are some incompatibility issues with the current app and a new app is in the works. We apologize for the inconvenience caused.</p>
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									Why am I being double charged by the app store and Robertparker.com?

									<QuestionAnswer>
										If you have an existing Robertparker.com subscription, we strongly recommend that you log into your account when first using the app. 
										<LineBreak />

										The in-app subscription is managed independently by the respective app stores (Apple and Google) and can only be canceled on your end.
										<LineBreak />
										You can cancel your in-app subscription by doing the following:

										<LineBreak />
										<TextStyle1>For Apple:</TextStyle1>
										<LineBreak />

										<p>1. <i>Open the Settings app.</i></p>

										<p>2. <i>Tap your name.</i></p>


										<p>3. <i>Tap Subscriptions. (If you don&apos;t see  &ldquo;Subscriptions, &ldquo; tap  &ldquo;iTunes & App Store &ldquo; instead. Then tap your Apple ID, tap View Apple ID, sign in, scroll down to Subscriptions, and tap Subscriptions.)</i></p>

										<p>4. <i>Tap the subscription that you want to manage.</i></p>


										<p>5. <i>Tap Cancel Subscription. If you don&apos;t see Cancel Subscription, the subscription is already canceled and won&apos;t renew.</i></p>
										<div id='unable-to-use-multiple-devices'></div>

										<LineBreak />
										<TextStyle1>For Google:</TextStyle1>
										<LineBreak />

										<p>1. <i>On your Android phone or tablet, open the Google Play Store.</i></p>

										<p>2. <i>Check if you&apos;re signed in to the correct Google Account.</i></p>

										<p>3. <i>Tap Menu {'>'} Subscriptions.</i></p>

										<p>4. <i>Select the subscription you want to cancel.</i> </p>

										<p>5. <i>Tap Cancel subscription.</i></p>

										<p>6. <i>Follow the instructions.</i></p>
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I have bought a personal subscription on the mobile app. Why am I unable to use it on multiple devices?
									<QuestionAnswer>
										Your subscription may not be linked to any account when you have purchased a subscription on your mobile. You can access your subscription on all mobile devices by linking your subscription to an account.
										<LineBreak />
										<TextStyle1>If you do not have an account:</TextStyle1>
										<LineBreak />

										<p>1. Tap on Account tab and tap on &quot;Create an account&quot; button.</p>

										<p>2. Fill in the form to register for an account.</p>

										<p>3. Tap on &quot;Register&quot; button.</p>
										<div id='current-subscription'></div>


										<p>4. Your subscription should be linked to your newly created account.</p>

										<LineBreak />
										<TextStyle1>If you already have an existing account:</TextStyle1>
										<LineBreak />
										<div id='restore-purchase'></div>


										<p>1. Tap on Account tab and tap on &quot;Log in&quot; button</p>

										<p>2. Enter your email and password.</p>

										<p>3. Tap on &quot;Log in&quot; button.</p>

										<p>4. Your subscription should be linked to your account.</p>
									</QuestionAnswer>
									<div id='transfer-subscriptions'></div>
								</QuestionTitle>

								<QuestionTitle>
									If you are on &quot;Current Subscriptions&quot; page with a message to inform you that your subscription is unlinked…
									<QuestionAnswer>

										<p>1. Tap on &quot;Log in to link subscriptions&quot; button</p>

										<p>2. Enter your email and password.</p>

										<p>3. Tap on &quot;Log in&quot; button.</p>

										<p>4. Your subscription should be linked to your account.</p>

									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									I bought my subscription on the app but want to change device platforms (from iOS to Android or vice versa). How do I transfer the subscription?
									<QuestionAnswer>
										The in-app subscription is managed independently by their respective app stores (Apple or Google) and is tied to that account. Subscriptions cannot be transferred cross-platforms. You should cancel your current in-app subscription and buy a new subscription on your new device.
										<LineBreak />
										<TextStyle3><Link to="">Instructions to cancel your Apple subscription</Link></TextStyle3>
										<LineBreak />
										<TextStyle3><Link to="">Instructions to cancel your Google subscription</Link></TextStyle3>
										<LineBreak />
										If you have bought your subscription from the website, you can login to your account on any device.
									</QuestionAnswer>
								</QuestionTitle>

							</AnswerContainer>
						</Row>
					</QAContainer>
				</>
			}
		</div>
	)
}

export default MobileAppIssue