/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useState, useEffect } from 'react'

import { ButtonUpdate, CompanyInfoContainer, CompanyParentContainer, ContactInfoContainer, DetailContainer, ImageContainer, NotificationPrompt, StatusSeparator, TradeDirectory, ViewDetailsSeparator } from './styles'
import LoadingOverlay from '../../../../components/Loading-overlay'
// import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
import { useEditUserCompanyDetailsMutation, useGetCompanyDetailsQuery, useGetSubscriptionDetailsQuery } from '../../../../app/services'
// import { validateURL } from '../../../../utils/ValidateUrl'
// import { addHttpsIfNeeded } from '../trade-partners/constants'
// import UpdateModal from './manage-group/UpdateModal'
import SaveModal from './manage-group/SaveModal'
import { isMobile } from 'react-device-detect'
import ErrorModal from '../../../../components/error-modal'
import TradeDirectoryModal from './TradeDirectoryModal'
import { useAppSelector } from '../../../../app/hooks'
import { Users as UsersFromStore } from '../../../../app/slices/Users'

const tradeDirectory = [
	{
		index: 0,
		label: 'Visible on Trade Directory',
	},
	{
		index: 1,
		label: 'Hidden / Private'
	}
]

const tradeDirectoryData = {
	'companyId': '',
	'name': '',
	'type': [],
	'street': '',
	'street_optional': '',
	'state': '',
	'city': '',
	'zip': '',
	'country': {
		'_id': '',
		'code': '',
		'name': ''
	},
	'contact_person': '',
	'email': '',
	'website': '',
	'phone': '',
	'about': '',
	'is_listed': '',
}

function ViewCompanyDetails(): ReactElement {

	const [tradeData, setTradeData] = useState<any>(tradeDirectoryData)
	const [isTradeModal, setTradeModal] = useState(false)
	const [isManager, setIsManager] = useState<any>()

	const [data, setData] = useState<any>()
	const [modal, setModal] = useState<any>()
	const [isUpdate, setIsUpdate] = useState<any>()
	const [isLoading, setIsLoading] = useState<any>()
	const [errorState, setErrorState] = useState<any>()
	const [success, setSuccess] = useState<any>()

	const { userData } = useAppSelector(UsersFromStore)

	const { data: subscriptionDetails } = useGetSubscriptionDetailsQuery(userData?.subscription?._id, { skip: !userData?.subscription?._id })
	const id = userData?.subscription?.manager?._id ? userData?.subscription?.manager?._id : userData?._id
	const { data: CompanyData, isLoading: CompanyDataLoading, refetch: refetchCompanyDetails } = useGetCompanyDetailsQuery(id, { skip: !userData })
	const [editUserCompanyDetailsMutation] = useEditUserCompanyDetailsMutation()

	useEffect(() => {
		refetchCompanyDetails()
	}, [])

	useEffect(() => {
		if (subscriptionDetails) {
			if (subscriptionDetails.success) {
				const userManager = subscriptionDetails?.data?.manager?.invite_manager_id ? subscriptionDetails?.data?.manager?.invite_manager_id === userData?._id : userData?.orbit_roles?.includes('project:customer-commercial-manager')
				setIsManager(userManager)
			}
		}
	}, [subscriptionDetails])

	useEffect(() => {
		if (CompanyData) {
			if (CompanyData.success) {
				const displayData = CompanyData.data[0]?.user_owned_companies[0]
				setData(displayData)
				setTradeData(displayData)
				setIsLoading(false)
			} else {
				setErrorState((state: any) => ({ ...state, isShow: true, message: 'Something went wrong while retrieving data.' }))
				setIsLoading(false)
			}
		} else {
			setIsLoading(false)
		}
	}, [CompanyData])

	useEffect(() => {
		if (success) {
			const timer = setTimeout(() => {
				setSuccess(false)
			}, 3000)
			return () => clearTimeout(timer)
		}
	}, [success])

	const scroolToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}

	const handleUpdate = (isSuccess: boolean, res: boolean, modal: string) => {
		setIsLoading(true)
		if (res) {
			setIsLoading(false)
			setIsUpdate(!isUpdate)
			setModal(modal)
			setSuccess(isSuccess)
			refetchCompanyDetails()
			scroolToTop()
		} else {
			setIsLoading(false)
			setModal(modal)
			setIsUpdate(!isUpdate)
		}
	}

	const closeModalError = () => {
		setErrorState((state: any) => ({ ...state, isShow: false, message: '' }))
	}

	const handleSelectChange = (event: any) => {
		const selectedKey = parseInt(event.target.value, 10)
		const updatedTradeData = { ...tradeData }
		if (selectedKey === 0) {
			updatedTradeData.is_listed = true
		} else {
			updatedTradeData.is_listed = false
		}
		setTradeData(updatedTradeData)
		setTradeModal(true)
	}

	const handleTradeModal = () => {
		setTradeModal(false)
	}

	const handleTradeProcced = async () => {
		setIsLoading(true)

		const payload = {
			companyId: tradeData?._id,
			name: tradeData?.name,
			type: tradeData?.type,
			street: tradeData?.address?.street,
			state: tradeData?.address?.state,
			city: tradeData?.address?.city,
			zip: tradeData?.address?.zip,
			country: tradeData?.address?.country,
			contact_person: tradeData?.contact_person,
			email: tradeData?.email,
			website: tradeData?.website_url,
			phone: tradeData?.phone,
			about: tradeData?.about,
			street_optional: tradeData?.address?.street_optional,
			is_listed: tradeData?.is_listed
		}

		try {
			const editCompanyDetails = await editUserCompanyDetailsMutation({
				params: {
					...payload
				},
				userID: userData._id
			}).unwrap()

			if (editCompanyDetails?.success) {
				refetchCompanyDetails()
				setIsLoading(false)
				setTradeModal(false)
				setSuccess(true)
			}

		} catch (error) {
			console.error('Failed to edit company details:', error)
		}
	}

	return (
		<>
			{(CompanyDataLoading || isLoading) && <LoadingOverlay />}
			{success &&
				<NotificationPrompt>
					<div className='success'>Changes Saved.</div>
				</NotificationPrompt>
			}
			<CompanyParentContainer >
				<>
					{isManager && <TradeDirectory>
						<div className='header'><b>Trade directory listing </b><br />
							Your company details are displayed on the Trade Directory by default.
						</div>
						<select value={data?.is_listed ? 0 : 1}
							onChange={(e) => handleSelectChange(e)}>
							{tradeDirectory.map((option, key: number) => (
								<option key={key} value={key}>
									{option.label}
								</option>
							))}
						</select>
					</TradeDirectory>}

					<CompanyInfoContainer isMobile={isMobile}>
						<DetailContainer size={'24px'} >
							<div className='parent-header'>Company Information</div>
						</DetailContainer>
						<ImageContainer>
							{data?.logo?.url ?
								<img
									className='image'
									src={data?.logo?.url}
									alt='Company Logo'
								/>
								:
								<div className='image-alternative'><i>No image</i></div>
							}
						</ImageContainer>
						<DetailContainer size={'16px'} >
							<div className='header'>Company Name: &nbsp;</div>
							<div className='value'>{data?.name}</div>
						</DetailContainer>

						<DetailContainer size={'16px'} >
							<div className='header-type'>Business Type: &nbsp;</div>
							<div className='value'>{data?.type ? data?.type.join(', ') : 'None'}</div>
						</DetailContainer>

						<DetailContainer size={'16px'} >
							<div className='header'>Business Address: &nbsp;</div>
						</DetailContainer>
						<DetailContainer size={'16px'} >
							<div className='next-line-value'>
								<p>{data?.address?.street}, {data?.address?.street_optional},  {data?.address?.state}</p>
								<p>{data?.address?.city}</p>
								<p>{data?.address?.zip}</p>
								<p>{data?.address?.country?.name}</p>
							</div>
						</DetailContainer>

						{isManager && <ButtonUpdate size={'14px'}>
							<div onClick={() => handleUpdate(false, false, 'company')}>UPDATE</div>
						</ButtonUpdate>}
					</CompanyInfoContainer>

					{isMobile && <ViewDetailsSeparator />}

					<ContactInfoContainer isMobile={isMobile}>
						<DetailContainer size={'24px'} >
							<div className='parent-header contact-info'>Contact Information</div>
						</DetailContainer>

						<DetailContainer size={'16px'} >
							<div className='header'>Contact Person: &nbsp;</div>
							<div className='value'>{data?.contact_person}</div>
						</DetailContainer>
						<DetailContainer size={'16px'} >
							<div className='header'>Business Email: &nbsp;</div>
							<div className='value'>{data?.email}</div>
						</DetailContainer>
						<DetailContainer size={'16px'} >
							<div className='header'>Website: &nbsp;</div>
							<div className='value'>{data?.website_url}</div>
						</DetailContainer>
						<DetailContainer size={'16px'} >
							<div className='header'>Phone: &nbsp;</div>
							<div className='value'>{data?.phone}</div>
						</DetailContainer>

						<DetailContainer size={'16px'} >
							<div className='header'>About my company: &nbsp;</div>
						</DetailContainer>
						<div style={{ maxHeight: '140px', padding: '0px', overflowY: 'auto' }}>
							<DetailContainer size={'16px'} >
								<div className='next-line-value'>{data?.about}</div>
							</DetailContainer>
						</div>
						<DetailContainer size={'16px'} >
							<div className='header'>Status: &nbsp;</div>
							<div className='value'>{data?.is_listed ? 'Listed in Trade Directory' : 'Hidden / Private'}</div>
						</DetailContainer>

						<StatusSeparator />
						{isManager && <ButtonUpdate size={'14px'}>
							<div onClick={() => handleUpdate(false, false, 'contact')}>UPDATE</div>
						</ButtonUpdate>}
					</ContactInfoContainer>
				</>
			</CompanyParentContainer>

			{isUpdate && (
				<SaveModal isOpen={isUpdate} modal={modal} onClose={handleUpdate} />
			)}

			{errorState?.isShow &&
				<ErrorModal messageBody={errorState?.message} isOpen={errorState?.isShow} onClose={closeModalError} />
			}

			{isTradeModal && (
				<TradeDirectoryModal
					isOpen={isTradeModal}
					onClose={handleTradeModal}
					onProceed={handleTradeProcced}
					content={
						<div>
							Your company details will be {!data?.is_listed ? 'listed in the' : 'delisted from the'} Trade Directory.
						</div>
					}
				/>
			)}
		</>
	)
}

export default ViewCompanyDetails