/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

// Styles
import { BackButton, CreditCardInformationContainer, DetailContainer, PageHeader, Row, SavePaymentButton, Separator } from './styles'
import LoadingOverlay from '../../../../components/Loading-overlay'
import { useCreatePaymentMethodMutation, useGetCustomerPaymentMethodQuery } from '../../../../app/services'
import ErrorModal from '../../../../components/error-modal'
import { selectTab } from '../../../../app/slices/Payment'
import InputForm from './components/InputForm'
import { useAppSelector } from '../../../../app/hooks'
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import ReCAPTCHA from 'react-google-recaptcha'
import { getCSURFToken } from '../../../../utils/CSRFToken'
import { dataCySelector } from '../../../../app/services/constant'
import { RP_ENV } from '../../../../configs'
import { reverseObfuscateConstant } from '../../../../utils/Constant'
import { IsUserAuthenticated } from '../../../../utils'

const formInitialState = {
	formErrors: {},
	card: {
		cardholderName: '',
		cardNumber: '',
		cvv: '',
		expiryDate:{
			year: new Date().getFullYear(),
			month: '01',
		},
		postalCode: '',
	},
	error: {
		isShow: false,
		message: ''
	}
}

const notifInit = {
	message: '',
	show: false
}

function SavePaymentMethods(): ReactElement {
	const { userData } = useAppSelector(UsersFromStore)


	const [createPayment, { data: createPaymentData, isLoading: createLoading, isError: createPaymentIsError }] = useCreatePaymentMethodMutation()
	const { refetch } = useGetCustomerPaymentMethodQuery(userData?._id, { skip: userData?._id ? false : true })

	const [showNotification, setShowNotification] = useState<{ message: string, show: boolean }>(notifInit)
	const [paymentState, setPaymentState] = useState<any>(formInitialState)
	const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
	const recaptchaRef = useRef(null)

	const history = useHistory()
	const dispatch = useDispatch()
	// const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

	useEffect(() => {
		if(!IsUserAuthenticated()){
			history.push('/login')
		}
	}, [])

	const generateMonthOptions = () => {
		// const currentMonth = paymentState.dates.year.toString() !== new Date().getFullYear().toString() ? 1 : new Date().getMonth() + 1

		const options = []
		for (let i = 1; i <= 12; i++) {
			const formattedMonth = i.toString().padStart(2, '0') // Pad single-digit month with leading zero
			options.push(<option key={formattedMonth} value={formattedMonth}>{formattedMonth}</option>)
		}
		return options
	}

	const generateYearOptions = () => {
		const currentYear = new Date().getFullYear()
		const endYear = currentYear + 10

		const options = []
		for (let year = currentYear; year <= endYear; year++) {
			options.push(<option key={year} value={year}>{year}</option>)
		}
		return options
	}

	const validateErrors = () => {
		let errors = { ...paymentState.formErrors }
		errors = { ...errors, ['cardholderName']: paymentState.card['cardholderName']?.trim() ? '' : 'Cardholder name is required' }
		errors = { ...errors, ['cardNumber']: paymentState.card['cardNumber']?.trim() ? '' : 'Card number is required' }
		errors = { ...errors, ['cvv']: paymentState.card['cvv']?.trim() ? '' : 'CVV is required' }
		errors = { ...errors, ['postalCode']: paymentState.card['postalCode']?.trim() ? '' : 'Postal code is required' }

		setPaymentState((state: any) => ({ ...state, formErrors: errors }))
		return getErrors(errors)
	}

	const getErrors = (formErrors: any) => {
		let error = ''
		for (const i in formErrors) {
			error += formErrors[i]
		}
		return error
	}

	const scrollToError = () => {
		window.scrollTo({ top: 100, behavior: 'smooth' })
	}

	const dateConfirmation = (specifiedDate: string) => {
		const todate = moment(new Date())
		return todate.isBefore(moment(specifiedDate, 'MM/YYYY')) || specifiedDate ===  todate.format('MM/YYYY')
	}

	const recaptchaHandler = (token: string | null) => {
		if(!token){
			setShowNotification({
				message: 'Please verify the recaptcha.',
				show: true,
			})
		}
		setRecaptchaToken(token)
	}

	const handleConfirmForm = async () => {
		try {
			if (!validateErrors() && userData) {
				const { card } = paymentState

				const formData = {
					name: card.cardholderName,
					number: card.cardNumber,
					cvv: card.cvv,
					expirationDate: card.expiryDate.month + '/' + card.expiryDate.year,
					postalCode: card.postalCode,
					customerId: userData._id,
					'recaptcha_token': recaptchaToken
				}

				if(!recaptchaToken) {
					setShowNotification({
						message: 'Please verify the recaptcha.',
						show: true,
					})
					return
				}

				if(!dateConfirmation(formData.expirationDate)){
					setPaymentState((prevState: any) => ({...prevState, card: {...prevState.card, cvv: '', expiryDate: {...prevState.card.expiryDate, month: '01', year: new Date().getFullYear()}}}))
					setShowNotification({
						message: 'You cannot proceed with the payment because the credit card has expired.',
						show: true,
					})
					return
				}

				const csrf_token = await getCSURFToken()
				await createPayment({params: formData, token: csrf_token})
			} else {
				setShowNotification({
					message: 'Something went wrong. Please try again.',
					show: true,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if(createPaymentData){
			if(createPaymentData.success){
				setPaymentState(formInitialState)
				refetch()
				cancelHandler()
			} else {
				setPaymentState((prevState: any) => ({...prevState, card: {...prevState.card, cvv: '', expiryDate: {...prevState.card.expiryDate, month: '01', year: new Date().getFullYear()}}}))
				handleRecaptchaReset()
				setShowNotification({
					message: createPaymentData.message,
					show: true,
				})
				scrollToError()
			}
		}

		if(createPaymentIsError){
			setShowNotification({
				message: 'Something went wrong. Please try again.',
				show: true,
			})
		}
	},[createPaymentData, createPaymentIsError])

	const handleCardChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { id, value } = event.target
		setPaymentState((state: any) => ({
			...state,
			card: {
				...state.card,
				[id]: value
			}
		}))

	}

	const handleRecaptchaReset = () => {
		if (recaptchaRef.current) {
			(recaptchaRef.current as any).reset()
			setRecaptchaToken(null)
		}
	}

	const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		const targetId = event.target.id
		let errors = { ...paymentState.formErrors }
		if (targetId === 'cardholderName') {
			errors = { ...errors, [targetId]: paymentState.card[targetId].trim() ? '' : 'Cardholder name is required' }
		} else if (targetId === 'cardNumber') {
			errors = { ...errors, [targetId]: paymentState.card[targetId].trim() ? '' : 'Card number is required' }
		} else if (targetId === 'cvv') {
			errors = { ...errors, [targetId]: paymentState.card[targetId].trim() ? '' : 'CVV is required' }
		} else if (targetId === 'postalCode') {
			errors = { ...errors, [targetId]: paymentState.card[targetId].trim() ? '' : 'Postal code is required' }
		}
		setPaymentState((state: any) => ({ ...state, formErrors: errors }))
	}

	const handleDateChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const { id, value } = event.target
		setPaymentState((prevState: any) => ({...prevState, card: {...prevState.card, expiryDate: {...prevState.card.expiryDate, [id]: value}}}))
	}

	const closeModal = () => {
		setShowNotification(notifInit)
	}

	const cancelHandler = () => {
		dispatch(selectTab(3))
		history.push('/my-subscription')
	}

	// const modalErrorHandler = (value: boolean) => {
	// 	setIsErrorModalOpen(value)
	// }

	return (
		<>
			<CreditCardInformationContainer>
				<div className={isMobile ? 'container' : 'desktop-container'}>
					<PageHeader>Payment Method</PageHeader>
					<br /><br />
					<InputForm label='Cardholder name' inputId='cardholderName' formErrors={paymentState.formErrors.cardholderName} handleCardChange={handleCardChange} value={paymentState.card.cardholderName} handleBlur={handleBlur} {...dataCySelector('cardholder-name-input')}/>
					<InputForm label='Card number' inputId='cardNumber' formErrors={paymentState.formErrors.cardNumber} handleCardChange={handleCardChange} value={paymentState.card.cardNumber} handleBlur={handleBlur} {...dataCySelector('card-number-input')}/>
					<div>
						<label>Expiry date</label><br />
						<div className='expiry-date-container'>
							<select value={paymentState.card.expiryDate.month} id='month' className='expiry-date' onChange={handleDateChange}>
								{generateMonthOptions()}
							</select> &ensp;
							<select value={paymentState.card.expiryDate.year} id='year' className='expiry-date' onChange={handleDateChange}>
								{generateYearOptions()}
							</select>
						</div>
					</div><br />
					<InputForm label='CVV' inputId='cvv' formErrors={paymentState.formErrors.cvv} handleCardChange={handleCardChange} value={paymentState.card.cvv} handleBlur={handleBlur} {...dataCySelector('cvv-input')}/>
					<InputForm label='ZIP / Postal Code' inputId='postalCode' formErrors={paymentState.formErrors.postalCode} handleCardChange={handleCardChange} value={paymentState.card.postalCode} handleBlur={handleBlur} {...dataCySelector('zip-code-input')}/>
					<Row>
						<Separator isAddon={false} />
					</Row>
					<div style={{ marginBottom: '2rem'  }}>
						<i>Please check the box below to proceed.</i>
						<ReCAPTCHA
							ref={recaptchaRef}
							sitekey={`${reverseObfuscateConstant(RP_ENV.CAPTCHA_KEY)}`}
							onChange={recaptchaHandler}
						/>
					</div>
					

					<div className='btn-separator'>
						<DetailContainer size={'16px'}>
							<BackButton onClick={cancelHandler} className={isMobile ? 'mobile back-button' : 'back-button'} {...dataCySelector('back-btn')}><img src='img/icons/arrow-back-ios.png' className='img-arrow'/> <span><b>&ensp;CANCEL</b></span></BackButton>
						</DetailContainer>
						<DetailContainer size={'16px'}>
							<SavePaymentButton onClick={handleConfirmForm} {...dataCySelector('save-payment-method-btn')}>SAVE PAYMENT METHOD</SavePaymentButton>
						</DetailContainer>
					</div>
				</div>
			</CreditCardInformationContainer>
			{createLoading && <LoadingOverlay />}
			{showNotification.show &&			
				<ErrorModal messageBody={showNotification.message} isOpen={showNotification.show} onClose={closeModal} />
			}
		</>
	)
}

export default SavePaymentMethods	