import React, { ChangeEvent, ReactElement, useState } from 'react'
import SubscriptionAndLogIn from './SubscriptionAndLogIn'
import TechnicalIssue from './TechnicalIssue'
import SiteContentAndFeatures from './SiteContentAndFeatures'
import Search from './Search'
import MobileAppIssue from './MobileAppIssue'
import { Menu, MenuOption, MenuOptionContainer } from './common'
import SelectionMenu from '../../Common/SelectionMenu'

const DEFAULT_DISPLAY = Menu.Subcription_Login

function FAQ(): ReactElement {

	const [currentDisplay, setCurrentDisplay] = useState(<SubscriptionAndLogIn />)
	const [selectedPlan, setSelectedPlan] = useState(DEFAULT_DISPLAY)

	const handleMenuChange = (key: number) => {
		let display = <SubscriptionAndLogIn />

		switch (key) {
		case 1: display = <SubscriptionAndLogIn />
			break
		case 2: display = <TechnicalIssue />
			break
		case 3: display = <SiteContentAndFeatures />
			break
		case 4: display = <Search />
			break
		case 5: display = <MobileAppIssue />
			break
		default:
			break
		}

		setCurrentDisplay(display)
		setSelectedPlan(key)
	}

	const selectionMenuHandler = (event: ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target
		const selectedMenu = parseInt(value)
		handleMenuChange(selectedMenu)
	}

	return (
		<div className="single-entry">
			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<MenuOptionContainer className="container">
					<MenuOption selected={(selectedPlan === Menu.Subcription_Login) ? true : false} onClick={() => (handleMenuChange(Menu.Subcription_Login))} >
							Subscriptions & Log-ins
						<div className={'line'}></div>
					</MenuOption>
					<MenuOption selected={(selectedPlan === Menu.Technical_Issue) ? true : false} onClick={() => (handleMenuChange(Menu.Technical_Issue))} >
							Technical Issues
						<div className={'line'}></div>
					</MenuOption>
					<MenuOption selected={(selectedPlan === Menu.Site_Content_Features) ? true : false} onClick={() => (handleMenuChange(Menu.Site_Content_Features))} >
							Site Content Features
						<div className={'line'}></div>
					</MenuOption>
					<MenuOption selected={(selectedPlan === Menu.Search) ? true : false} onClick={() => (handleMenuChange(Menu.Search))} >
							Search
						<div className={'line'}></div>
					</MenuOption>
					<MenuOption selected={(selectedPlan === Menu.Mobile_App_Issue) ? true : false} onClick={() => (handleMenuChange(Menu.Mobile_App_Issue))} >
							Mobile App Issues
						<div className={'line'}></div>
					</MenuOption>
				</MenuOptionContainer>
				<div className="container">
					<SelectionMenu title='FAQ' onSelectionMenu={selectionMenuHandler} defaultValue={1}>
						<option value={1}>Subscriptions {'&'} Log-ins</option>
						<option value={2}>Technical Issues</option>
						<option value={3}>Site Content Features</option>
						<option value={4}>Search</option>
						<option value={5}>Mobile App Issues</option>
					</SelectionMenu>
				</div>
				<div className="container">
					{currentDisplay}
				</div>
			</div>
		</div>
	)
}
export default FAQ