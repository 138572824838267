import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

type AnalyticsInitialState = {
    checkout: {
        planId: string;
        seats: number;
        tasting_notes: string;
        discountValue: number;
		transactionId: string;
		discountCoupon: string;
    }
}

const initialState: AnalyticsInitialState = {
	checkout: {
		planId: '',
		seats: 0,
		tasting_notes: '',
		discountValue: 0,
		transactionId: '',
		discountCoupon: '',
	}
}

const AnalyticsSlice = createSlice({
	name: 'AnalyticsSlice',
	initialState,
	reducers: {
		UpdateSuccessAnalytics: (state, action) => ({
			...state,
			checkout: {
				...state.checkout,
				planId: action.payload.planId,
				seats: action.payload.seats,
				tasting_notes: action.payload.tasting_notes,
				discountValue: action.payload.discountValue,
				transactionId: action.payload.transactionId,
				discountCoupon: action.payload.discountCoupon
			}
		})
	}
})

export const { UpdateSuccessAnalytics } = AnalyticsSlice.actions

export default AnalyticsSlice.reducer

export const Analytics = (state: RootState): AnalyticsInitialState => state.Analytics