import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { RPColors } from '../../../../styles/CSSConstant'

export const OtherSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & span{
        font-weight: bold;
        font-size: 24px;
    }
`

export const ActionButton = styled.button`
    height: auto;
    width: fit-content;
    font-weight: bold;
    padding: 6px 25.5px;
    border-radius: 8px;
    border: solid 1px #ccc;
    background-color: #73333f;
    color: #ffffff;
    text-align: center;

    &:hover {
        background-color: #8E4250;
    }

    &:disabled {
        background-color: #ccc;
        color: #666;
        cursor: not-allowed;
    }
`

//NEW DESIGN REVAMP
interface CardContainerProps {
    width?: string;
    height?: string;
    withBackground?: boolean;
}

export const SubscriptionHeader = styled.h2`
    font-size: 24px;
    text-align: center;
    color: ${RPColors.darkPrimary};
    font-weight: bold;
    margin-bottom: 40px;
    padding-top: ${isMobile ? '40px' : '0'};
    font-family: 'Libre Franklin', sans-serif;
`
export const SubscriptionCardContainer = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
`

export const SubscriptionTypesContainer = styled.div`
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32px;

    & a {
        text-decoration: none !important;
    }
    
    & :hover {
        border-radius: 12px;
        background-color: #fff3fa;
    }
    
    @media (max-width: 991px) {
        flex-direction: column;
    }
`

export const CardContainer = styled.div<CardContainerProps>`
    width: ${(props) => props.width || 'auto'};
    height: ${(props) => props.height || 'auto'};
    flex-grow: 0;
    padding: ${(props) => props.withBackground ? '32px 38px 32px 38px' : '52px 38px 69px 38px'};
    border-radius: 12px;
    border: solid 1px ${RPColors.borderColor};
    background-color: ${(props) => props.withBackground ? RPColors.secondaryColor : '#fff'};
    color: ${RPColors.darkPrimary};

    @media (max-width: 991px) {
        width: auto;
        height: auto;
        padding: 32px 4px 32px 4px;
    }
   
`

export const CardContent = styled.div<{ isCenter?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.isCenter ? 'center' : 'left')};
    align-items: ${(props) => (props.isCenter ? 'center' : 'left')};

    & .img-container {
        

        width: 60px;
        height: 70px;
        margin-bottom: 2px;
    }

    & h5 {
        font-size: 18px;
        text-align: ${(props) => (props.isCenter ? 'center' : 'left')};
        font-weight: bold;
    }

    & p {
        font-size: 14px;
        text-align: ${(props) => (props.isCenter ? 'center' : 'left')};
        line-height: 16.8px;
    }

    & p.redeem-sub {
        width: auto;
    }

    & span {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
        
    & .redeem-content-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
    }

    @media (max-width: 991px) {
        margin: 16px;

        & p.redeem-sub {
            width: auto;
        }
        
        & span {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        & h5 p {
            text-align: center;
        }
    }

`

export const Button = styled.button`
    width: 141px;
    height: 41px;
    align-items: center;
    border-radius: 8px;
    background-color: ${RPColors.buttonColor};
    color: white !important;

    @media (max-width: 991px) {
        width: 100%;
    }
`

export const Seperator = styled.div<{width?: string, isCommercial?: boolean}>`
    margin: ${(props) => (props.isCommercial ? isMobile ? '25px 0 51px 0' : '0px 0 51px 0' : '51px 0')};
    width: ${({ width }) => width ? width : 'auto'};
    height: 1px;
    background-color: ${RPColors.borderColor};
`

export const SubscriptionSecondHeader = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: ${RPColors.darkPrimary};

    & h2 {
        font-size: 22px;
        font-weight: bold;
    }

    & p {
        margin: 0;
        font-size: 16px;
    }
`

// TABLE

export const FeaturedWrapper = styled.div<{ isMobile?: boolean, isUpgrade?: boolean }>`
    overflow: hidden;
	width: 100%;
    border-top: 2px solid ${RPColors.borderColor};
    border-bottom: 2px solid ${RPColors.borderColor};
    border-left: ${(props) => props.isMobile ? props?.isUpgrade ? '2px': 0 : '2px'} solid ${RPColors.borderColor};
    border-right: ${(props) => props.isMobile ? props?.isUpgrade ? '2px': 0 : '2px'} solid ${RPColors.borderColor};
    border-radius: ${(props) => props.isMobile ? '0' : '12px'};

    & .flex-featured-header {
        display: flex;
        justify-content: end;
    }


    & .flex-featured {
        display: flex;
        width: auto;
    }

    & .flex-featured-value {
        width: auto;
        display: flex;
        flex-direction: row;
    }

    @media (max-width: 991px) {
        & .flex-featured-header {
            display: flex;
            justify-content: center;
        }

        & .flex-featured {
            flex-direction: column;
        }
    }
`

export const FeaturedContainer = styled.div<{ withBackground?: boolean, hasBorderBtm?: boolean }>`
    font-size: 14px;
	width: ${isMobile ? '607px' : '50vw'};
    padding: 24px 23px;
	border-right: 1px solid ${RPColors.borderColor};
	border-bottom: ${(props) => (props.hasBorderBtm ? `1px solid ${RPColors.borderColor}` : 'none')};
    background-color: ${(props) => (props.withBackground ? RPColors.secondaryColor : '')};

    
    @media (max-width: 1199px) {
        width: 625px;
    }

    @media (max-width: 991px) {
        width: auto;
        text-align: center;
        border-right: none;
    }
`

export const FullFeaturedContainer = styled.div<{ withBackground?: boolean, hasBorderBtm?: boolean }>`
    font-size: 14px;
	width: 100%;
    padding: 24px 23px;
	/* border-right: 1px solid ${RPColors.borderColor}; */
	border-bottom: ${(props) => (props.hasBorderBtm ? `1px solid ${RPColors.borderColor}` : 'none')};
    background-color: ${(props) => (props.withBackground ? RPColors.secondaryColor : '')};
    display: flex;
    align-items: center;
    align-content: center;

    & div {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
    
    & p {
        font-family: 'Libre Franklin', sans-serif !important;
        font-weight: 500;
        text-align: center;
    }
`

export const FeaturedContainerPersonal = styled.div<{ withBackground?: boolean, hasBorderBtm?: boolean, hasBorderRadius?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${isMobile ? '250px' : '25vw'};
	border-right: 1px solid ${RPColors.borderColor};
	border-bottom: ${(props) => (props.hasBorderBtm ? `1px solid ${RPColors.borderColor}` : 'none')};
    background-color: ${(props) => (props.withBackground ? RPColors.secondaryColor : 'white')};

    & p {
        font-family: 'Libre Franklin', sans-serif !important;
        margin-top: 20px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: normal;
        text-align: center;
    }

    @media (max-width: 1199px) {
        width: ${isMobile ? '150px' : '25vw'};
    }

    @media (max-width: 991px) {
        width: 100%;
        border-radius: ${(props) => (props.hasBorderRadius ? '12px 0 0 0' : '0')};
    }
`


export const FeaturedContainerCommercial = styled.div<{ withBackground?: boolean, hasBorderRadius?: boolean, hasBorderBtm?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${isMobile ? '250px' : '25vw'};
	border-bottom: ${(props) => (props.hasBorderBtm ? `1px solid ${RPColors.borderColor}` : 'none')};
    background-color: ${(props) => (props.withBackground ? RPColors.secondaryColor : 'white')};
    border-radius: ${(props) => (props.hasBorderRadius ? '0 12px 0 0' : '0')};

    & p {
        font-family: 'Libre Franklin', sans-serif !important;
        margin-top: 20px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: normal;
        text-align: center;
    }

    @media (max-width: 1199px) {
        width: ${isMobile ? '150px' : '25vw'};
    }

    @media (max-width: 991px) {
        width: 100%;
    }
`
// FOR COMMERCIALS OFFERS STYLED TABLE

export const FeaturedTitleWrapper = styled.div<{isUpgrade?: boolean}>`
    width: auto;
    margin-left: ${(props) => (props.isUpgrade ? '354px' : '274px')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 1199px) {
        margin-left: ${(props) => (isMobile ? 0 : props.isUpgrade ? '33.33%' : '25%')};
    }
`

export const FeaturedCommercialTitle = styled.div<{ hasBorderRadiusRight?: boolean, hasBorderLeft?: boolean, isGold?: boolean, hasBorderRight?: boolean, hasBorderRadiusLeft?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	// width: ${isMobile ? '100%' : '25vw'};
	width: 100%;
    border-left: ${(props) => (props.hasBorderLeft ? `1px solid ${RPColors.borderColor}` : '0')};
	border-right: ${(props) => (props.hasBorderRight ? `1px solid ${RPColors.borderColor}` : '0')} ;
    background-color: ${(props) => (props.isGold && RPColors.goldColor)};
    border-radius: ${(props) => (props.hasBorderRadiusRight ? '0 12px 0 0' : props.hasBorderRadiusLeft ? '12px 0 0 0' : '0')};

    & p {
        font-family: 'Libre Franklin', sans-serif !important;
        margin-top: 20px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: normal;
        text-align: center;
    }

    @media (max-width: 991px) {
        & p {
            margin-top: 20px;
            font-size: 12px;
        }
    }

    @media (max-width: 346px) {
        & p {
            font-size: 10px;
        }
    }
`

export const FullCommercialContainer = styled.div`
    font-size: 14px;
	width: 100%;
	border-top: 1px solid ${RPColors.borderColor};
    background-color: ${RPColors.primaryColor};
    text-align: center;

    & p {
        color: white;
        font-weight: 500;
        margin: 0;
        padding: 16px 0 16px 0;
    }

    @media (max-width: 346px) {
        & p {
            font-size: 12px;
        }
    }
`

export const ListFeaturedContainer = styled.div<{ withBackground?: boolean, hasBorderBtm?: boolean, isUpgrade?: boolean }>`
    font-size: 14px;
	width: ${(props) => (isMobile ? '100%' : props.isUpgrade ? '500px' : '350px')};
    padding: 20px 24px;
	border-right: ${isMobile ? '0' : `1px solid ${RPColors.borderColor}`};
	border-bottom: ${(props) => (props.hasBorderBtm ? `1px solid ${RPColors.borderColor}` : 'none')};
    background-color: ${(props) => (props.withBackground ? RPColors.secondaryColor : 'white')};
    font-weight: 500;
    text-align: ${isMobile ? 'center' : 'left'};

    
    @media (max-width: 346px) {
        font-size: 12px;
    }

    @media (max-width: 1199px) {
        width: ${(props) => (isMobile ? 'auto' : props.isUpgrade ? '47.33%' : '32%')};
    }
`
export const ListDataWrapper = styled.div<{ hasBorderBottom?: boolean}>`
    display: flex;
    flex-direction: ${isMobile ? 'column' : 'row'};
    border-bottom: ${(props) => (props.hasBorderBottom ? `1px solid ${RPColors.borderColor}` : '0')};

    & .data-value {
        // width: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`
export const FeaturedDatalist = styled.div<{ hasBorderRadius?: boolean, hasBorderLeft?: boolean, isCheck?: boolean, isGold?: boolean, hasBackgroundColor?: boolean, isDetailed?: boolean, hasBorderRight?: boolean, hasBorderRadiusRight?: boolean, hasBorderRadiusLeft?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	// width: ${isMobile ? '100%' : '252px'};
	width: 100%;
    border-left: ${(props) => (props.hasBorderLeft ? `1px solid ${RPColors.borderColor}` : '0')};
	border-right: ${(props) => (props.hasBorderRight ?  `1px solid ${RPColors.borderColor}` : '0')};
    background-color: ${(props) => (props.isGold ? RPColors.goldColor : props.hasBackgroundColor ? RPColors.secondaryColor : 'white')};
    border-radius: ${(props) => (props.hasBorderRadius ? '0 12px 0 0' : '0')};
    border-radius: ${(props) => (props.hasBorderRadius ? '0 12px 0 0' : props.hasBorderRadiusRight ? '0 0 12px 0' : props.hasBorderRadiusLeft ? '0 0 0 12px' : '0')};

    & p {
        font-family: 'Libre Franklin', sans-serif !important;
        margin-top: 20px;
        font-size: ${(props) => (props.isCheck ? '24px' : '14px')};
        font-weight: ${(props) => (props.isCheck ? 'normal' : '500')};
        letter-spacing: normal;
        text-align: center;
    }

    @media (max-width: 1199px) {
        width: ${isMobile ? '100%' : ''};
    }

    @media (max-width: 991px) {
        width: ${isMobile ? '100%' : '173px'};
    }

    @media (max-width: 767px) {
        width: ${isMobile ? '100%' : '112px'};

        & p {
            font-size: ${(props) => (props.isCheck ? '24px' : props.isDetailed ? '12px' : '14px')};
        }
    }

    @media (max-width: 346px) {
        & p {
            font-size: ${(props) => (props.isCheck ? '24px' : props.isDetailed ? '10px' : '14px')};
        }
    }

`

// PLAN TYPES CARD

export const PlanTypesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center !important;
    gap: 32px;
`
export const PlanTypesCard = styled.div<{ isCenterCard?: boolean, isMobile?: boolean, isCommercial?: boolean}>`
    display: flex;
    margin: ${(props) => (props.isMobile ? '0 20px 0 20px' : !props.isCenterCard ? '0' : '0 0 51px 0')};
    height: ${(props) => (props.isCommercial ? props?.isCenterCard ? '677px' : '623px' : '100%')};
    border-radius: 12px;
    border: solid 1px ${RPColors.borderColor};
`

export const PlanTypesCardContent = styled.div<{isMobile?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Libre Franklin', sans-serif;
    height: 100%;

    & .recommended-badge {
        width: 100%;
        height: 50px;
        padding: 12px 0 0;
        border-radius: 12px 12px 0 0;
        border-bottom: solid 1px ${RPColors.borderColor};
        background-color: ${RPColors.goldColor};

        & p {
            font-weight: 500;
            font-size: 16px;
            text-align: center;
        }
    }

    & .plan-details {
        height: 100%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        padding: 0 40px 1.5rem 40px;
    }

    & .from-text {
        font-size: 12px;
        margin-bottom: 8px;
        flex-grow: 0;
        font-family: Libre Franklin;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #311820;
    }

    & .plan-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: ${RPColors.darkPrimary};

        & .plan-title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 24px;
            
            & p {
                margin: 0;
            }
        }

        & .plan-sub-title {
            font-size: 12px;
            font-weight: bold;
            & p {
                margin: 0;
            }
        }

        & .plan-amount {
            display: flex;
            flex-direction: row;
            justify-content: end;
            align-items: end;

            & p.amount-price {
                font-size: 40px;
                font-weight: 800;
                margin: 0 !important;

            }

            & span.amount-indicator {
                font-size: 20px;
                font-weight: 600;
                margin: 0 !important;
                
            }
        }

        & .plan-billing{
            & p {
                margin: 0;
                font-size: 12px;
                line-height: 14.4px;
            }
        }
    }

    & .features-info {
        width: 285px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 0;

        & div {
            width: 100%;
        }

        & p {
            font-size: 12px;
            font-weight: 600;
            line-height: 1.2;
            margin: 0;
        }

        & hr {
            width: 100%;
            background-color: ${RPColors.borderColor} !important;
        }
    }
    
    & .subscribe-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 25px;
    }

    & .subscribe-button {
        cursor: pointer;
        width: 179px;
        height: 41px;
    }

    & .gift-subs {
        margin-top: 15px;
        & a, p {
                font-size: 12px;
                font-weight: 500;
                color: ${RPColors.darkPrimary} !important;
                text-decoration: underline !important;
        }
    }



    @media (max-width: 420px) {
        width: 325px;
    }

    @media (max-width: 360px) {
        width: 300px;

        & .plan-details {
            margin-top: 20px;
            gap: 24px;
        }

        & .plan-info {
            & .plan-title {
                font-size: 15px;
            }

            & .plan-sub-title {
                font-size: 11px;
            }

            & .plan-amount {
                & p.amount-price {
                    font-size: 36px;
                }

                & span.amount-indicator {
                    font-size: 15px;
                }
            }
        }

        & .features-info {
            width: 230px;
            
            & p {
                font-size: 11px;
            }
        }
    }

    @media (max-width: 325px) {
        width: 250px;

        & .plan-details {
        gap: 16px;
    }

        & .plan-info {
            & .plan-title {
                font-size: 15px;
            }

            & .plan-sub-title {
                font-size: 11px;
            }

            & .plan-amount {
                & p.amount-price {
                    font-size: 36px;
                }

                & span.amount-indicator {
                    font-size: 15px;
                }
            }
        }

        & .features-info {
            width: 230px;
            
            & p {
                font-size: 11px;
            }
        }

    }

    @media (max-width: 260px) {
        width: 200px;

        & .plan-details {
        gap: 14px;
    }

        & .plan-info {
            & .plan-title {
                font-size: 15px;
            }

            & .plan-sub-title {
                font-size: 11px;
            }

            & .plan-amount {
                & p.amount-price {
                    font-size: 28px;
                }

                & span.amount-indicator {
                    font-size: 12px;
                }
            }
        }

        & .features-info {
            width: 200px;
            padding: 0 10px 0 10px;
           
            & p {
                font-size: 9px;
                padding: '0 2px 0 2px';
            }
        }

        & .subscribe-container {
            padding-bottom: 20px;
        }

        & .subscribe-button {
            width: 120px;
            height: 28px;
        }


        & .gift-subs {
            & a, p {
                font-size: 8px;
            }
        }
    }
`

export const CarouselWrapperForThree = styled.div<{ currentSlide?: number }>`
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center !important;
    transition: transform 0.5s ease-in-out;

    transform: ${({ currentSlide = 0 }) => 
		currentSlide === 2
			? 'translateX(-408px)'
			: currentSlide === 0
				? 'translateX(408px)'
				: 'translateX(0px)'
}; 

    @media (max-width: 420px) {
        transform: ${({ currentSlide = 0 }) => 
		currentSlide === 2
			? 'translateX(-368px)'
			: currentSlide === 0
				? 'translateX(368px)'
				: 'translateX(0px)'
};
    }
    
    @media (max-width: 360px) {
        transform: ${({ currentSlide = 0 }) => 
		currentSlide === 2
			? 'translateX(-342px)'
			: currentSlide === 0
				? 'translateX(342px)'
				: 'translateX(0px)'
};
    }

    @media (max-width: 325px) {
        transform: ${({ currentSlide = 0 }) => 
		currentSlide === 2
			? 'translateX(-292px)'
			: currentSlide === 0
				? 'translateX(292px)'
				: 'translateX(0px)'
};
    }

    @media (max-width: 260px) {
        transform: ${({ currentSlide = 0 }) => 
		currentSlide === 2
			? 'translateX(-242px)'
			: currentSlide === 0
				? 'translateX(242px)'
				: 'translateX(0px)'
};
    }
`

export const CarouselWrapperForTwo = styled.div<{ currentSlide?: number }>`
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center !important;
    transition: transform 0.5s ease-in-out;

    transform: ${({ currentSlide = 0 }) => 
		currentSlide === 1
			? 'translateX(-180px)' : 'translateX(200px)'
}; 

    @media (max-width: 420px) {
        transform: ${({ currentSlide = 0 }) => 
		currentSlide === 1
			? 'translateX(-180px)' : 'translateX(200px)'
};
    }
    
    @media (max-width: 360px) {
        transform: ${({ currentSlide = 0 }) => 
		currentSlide === 1
			? 'translateX(-170px)' : 'translateX(175px)'
};
    }

    @media (max-width: 325px) {
        transform: ${({ currentSlide = 0 }) => 
		currentSlide === 1
			? 'translateX(-145px)' : 'translateX(150px)'
};
    }

    @media (max-width: 260px) {
        transform: ${({ currentSlide = 0 }) => 
		currentSlide === 1
			? 'translateX(-120px)' : 'translateX(123px)'
};
    }
`

export const CarouselWrapper = styled.div<{ currentSlide?: number }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center !important;
    transition: transform 0.5s ease-in-out;
`

export const SubscribeButton = styled.div<{ isConsult?: boolean }>`
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${RPColors.buttonColor};
    border-radius: 4px;
    margin-top: ${(props) => (props.isConsult ? '100px' : '0px')};
    cursor: pointer;

    color: white;
    font-size: 14px;
    font-weight: 500;

    :hover {
        opacity: 90%;
    }

    @media (max-width: 260px) {
        font-size: 10px;
    }
`

// export const NavButton = styled.button`
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     background-color: rgba(0, 0, 0, 0.5);
//     color: white;
//     border: none;
//     padding: 10px;
//     cursor: pointer;
//     z-index: 1;

//     &:hover {
//         background-color: rgba(0, 0, 0, 0.7);
//     }

//     &:first-of-type {
//         left: 10px;
//     }

//     &:last-of-type {
//         right: 10px;
//     }
// `

export const IndicatorWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`

export const Indicator = styled.div<{ isActive: boolean }>`
    width: ${({ isActive }) => (isActive ? '12px' : '8px')};
    height: ${({ isActive }) => (isActive ? '12px' : '8px')};
    margin: 0 5px;
    border-radius: 50%;
    background-color: ${({ isActive }) => (isActive ? RPColors.borderColor : '#d9d9d9')};
    transition: background-color 0.3s ease-in-out;
`