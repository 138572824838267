/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Wine from '../structure/classes/Wine'

export function GetWineNameDisplay(wine: any): string {
	const container: string[] = []

	container.push(wine.vintage)

	if (wine.producer) {
		if(wine.producer?.display_name){
			container.push(wine.producer.display_name)
		}else{
			container.push(wine.producer)
		}
	}

	return `${container.join(' ')}`
}

/**
 * Filter a collection of WineJSON for wines that have computed ratings of more than 90 pts.
 * WineJSON are data that are taken straight from the API, which has not been mapped to the wine class.
 * @param wines
 * @returns
 */
export function GetShortlistedResults<WineInJSON>(
	wines: WineInJSON[]
): WineInJSON[] {
	return wines.filter((wine: any) =>
		wine.rating?.computed ? Number(wine.rating.computed) > 90 : null
	)
}

export function ConvertJSONWineToClass(item: any): Wine {
	const wineInJSON: WineJSON = {
		id: item.objectID ? item.objectID : item.id,
		appellation: item.appellation,
		certified: item.certified,
		color_class: item.color_class,
		country: item.country,
		drink_date: item.drink_date,
		dryness: item.dryness,
		image: item.image,
		importers: item.importers,
		locale: item.locale,
		location: item.location,
		maturity: item.maturity,
		name: item.name,
		display_name: item.display_name,
		producer: item.producer,
		publication_code: item.publication_code,
		rating: item.rating,
		rating_display: item.rating_display,
		region: item.region,
		site: item.site,
		tasting_note_count: item.tasting_note_count,
		type: item.type,
		url: item.url,
		varieties: item.varieties || item.variety,
		vintage: item.vintage,
		date_last_tasted: item.date_last_tasted
	}

	return new Wine(wineInJSON)
}

export function ConvertJSONWineCollectionToClass<Generic>(
	input: Generic[]
): Wine[] {
	return input.map((item: any) => ConvertJSONWineToClass(item))
}
