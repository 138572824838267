import React, { ReactElement, useState, ChangeEvent } from 'react'
import { HashLink } from 'react-router-hash-link'
import { isMobile } from 'react-device-detect'
import { sanitizeUrl } from '@braintree/sanitize-url'
import {
	PageTitle,
	QAContainer,
	QuestionTitle,
	Question,
	QuestionAnswer,
	QuestionContainer,
	AnswerContainer,
	TextStyle3,
	Row,
	AnswerContainerMobile,
} from './common'

function Search(): ReactElement {

	const [selectedOption, setSelectedOption] = useState('')

	const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const selectedValue = event.target.value
		setSelectedOption(selectedValue)

		// Check the selected value and navigate to the target ID
		if (selectedValue === 'question1') {
			window.location.href = 'faq/#search-wine'
		} else if (selectedValue === 'question2') {
			window.location.href = 'faq/#search-article'
		} else if (selectedValue === 'question3') {
			window.location.href = 'faq/#advance-search'
		} else if (selectedValue === 'question4') {
			window.location.href = 'faq/#find-reviews'
		}
		// Add more conditions as needed
	}
	return (
		<div role="main">
			{isMobile ?
				<><PageTitle>Technical Issues</PageTitle>
					<QAContainer>

						<span className='jump-question'>Jump to question</span>
						<select value={selectedOption} onChange={(e) => handleChange(e)} className='jump-question-select component-design'>
							<option value=''>- Select a question -</option>
							<option value='question1'>How do I search for a wine?</option>
							<option value='question2'>How do I search for an article?</option>
							<option value='question3'>Can I use the advanced search options like I could on the old website (erobertparker.com)?</option>
						</select>
						<Row>
							<AnswerContainerMobile>
								<QuestionTitle>
									How do I search for a wine?
									<div id='search-article'></div>

									<QuestionAnswer>
										<p>You can type the name of a wine, producer or region in the search bar on the top of the page and press enter to trigger the search. There will be category suggestions in the drop-down list as you type.</p>
										<p>If you do not see the desired suggestion in the drop-down list, you can click on “See all wine results” to view wine results that match your keyword.</p>
										<p>You can refine your search results even further by using the filters in the left column of the search results page.</p>
									</QuestionAnswer>
								</QuestionTitle>

								<QuestionTitle>
									How do I search for an article?
									<QuestionAnswer>
										<p>You can search for a keyword (e.g., region, vintage, reviewer’s name) in the search bar at the top of the page and then click on “See all article results” in the suggestions drop-down.</p>
										<p>You can refine your search results even further by using the filters in the left column of the search results page.</p>
									</QuestionAnswer>
									<div id='advance-search'></div>
								</QuestionTitle>

								<QuestionTitle>
									Can I use the advanced search options like I could on the old website (erobertparker.com)?
									<QuestionAnswer>
										<p>Yes, they are available to you when you click on “Advanced search” under the search bar. Currently, filters by rating and price are not yet available. If you have suggestions on how to improve the search functionality further, we would like to hear from you!</p>
										<p>Please email us at <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}
										>support@robertparker.com</a></TextStyle3></p>
									</QuestionAnswer>
								</QuestionTitle>
							</AnswerContainerMobile>
						</Row>

					</QAContainer>
				</>
				:
				<>
					<PageTitle>Search</PageTitle>
					<QAContainer>
						<Row>
							<QuestionContainer>
								<Question>
									<HashLink to='#search-wine'>How do I search for a wine?</HashLink>
								</Question>
								<Question>
									<HashLink to='#search-article'>How do I search for an article?</HashLink>
								</Question>
								<Question>
									<HashLink to='#advance-search'>Can I use the advanced search options like I could on the old website (erobertparker.com)?</HashLink>
								</Question>
							</QuestionContainer>
							<AnswerContainer>
								<QuestionTitle>
									How do I search for a wine?
									<div id='search-article'></div>

									<QuestionAnswer>
										<p>You can type the name of a wine, producer or region in the search bar on the top of the page and press enter to trigger the search. There will be category suggestions in the drop-down list as you type.</p>
										<p>If you do not see the desired suggestion in the drop-down list, you can click on “See all wine results” to view wine results that match your keyword.</p>
										<p>You can refine your search results even further by using the filters in the left column of the search results page.</p>
									</QuestionAnswer>
									
								</QuestionTitle>

								<QuestionTitle>
									How do I search for an article?
									<QuestionAnswer>
										<p>You can search for a keyword (e.g., region, vintage, reviewer’s name) in the search bar at the top of the page and then click on “See all article results” in the suggestions drop-down.</p>
										<p>You can refine your search results even further by using the filters in the left column of the search results page.</p>
									</QuestionAnswer>
									<div id='advance-search'></div>
								</QuestionTitle>

								<QuestionTitle>
									Can I use the advanced search options like I could on the old website (erobertparker.com)?
									<QuestionAnswer>
										<p>Yes, they are available to you when you click on “Advanced search” under the search bar. Currently, filters by rating and price are not yet available. If you have suggestions on how to improve the search functionality further, we would like to hear from you!</p>
										<p>Please email us at <TextStyle3><a href={sanitizeUrl('mailto:support@robertparker.com')}
										>support@robertparker.com</a></TextStyle3></p>
									</QuestionAnswer>
								</QuestionTitle>
							</AnswerContainer>
						</Row>

					</QAContainer>
				</>
			}
		</div>
	)
}

export default Search