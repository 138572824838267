/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react'
import { SuccessPageContainer } from '../styles'
// import { useGetUserLoginMutation } from '../../../../../app/services'
import { useHistory, useLocation } from 'react-router-dom'
// import LoadingOverlay from '../../../../../components/Loading-overlay'
// import { getCSURFToken } from '../../../../../utils/CSRFToken'
// import { deviceType } from '../../../../../helper/constants'
import ErrorModal from '../../../../../components/error-modal'
// import { SaveAuthenticationSession } from '../../../../../utils'
// import { UpdateUserAuthDetails } from '../../../../../app/slices/Authentication'
// import { setCookie } from '../../../../../utils/CookieUtils'
// import { useDispatch } from 'react-redux'

interface ErrorState {
	isShow: boolean
	message: string
}

// interface CommonState {
// 	token?: string
// 	title?: string
// 	firstName?: string
// 	lastName?: string
// 	email?: string
// 	invitor?: string
// 	password?: string
// 	[key: string]: any
// }

interface LocationState {
	manager?: string;
	[key: string]: any;
}

const SuccessPage = (): ReactElement => {
	// const dispatch = useDispatch()
	// const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()
	const [errorState, setErrorState] = useState<ErrorState | null>(null)

	const location = useLocation<LocationState>()
	const manager = location.state || {}

	// const [userLoginMutation, { data: loginUserData, isLoading: loginUserIsLoading, isSuccess: isLoginUserSuccess }] = useGetUserLoginMutation()

	const loginUser = async () => {
		history.push('/')
	}

	const closeModalError = () => {
		setErrorState(null)
	}

	return (
		<SuccessPageContainer>
			<div className='header'>Join Group</div>
			<div className='container'>
				<div className='sub-header'>You are now a member of <b>{manager.manager}</b>`s group</div>
				<div className='value'>You can now view articles and wine tasting notes. Please note that you will not be able to purchase subscriptions as a group member.</div>
				<div className='home-button' onClick={() => loginUser()}>GO TO HOME</div>
			</div>

			{errorState?.isShow &&
				<ErrorModal messageBody={errorState.message} isOpen={errorState.isShow} onClose={closeModalError} />
			}
		</SuccessPageContainer>
	)
}

export default SuccessPage
