/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import SuccessActivation from '../../../../components/success-page/SuccessPage'
import NonExistPage from '../404-page/NonExistPage'
import PersonalCheckout from './plans/PersonalCheckout'
import CommercialCheckout from './plans/CommercialCheckout'

const bodyContent = [
	{
		header: 'Personal subscription activated',
		bodyOne:'Your Personal subscription has been activated. The subscription will end on',
		bodyTwo: 'You now have access to all articles and tasting notes on the site.',
	},
	{
		header: 'Commercial subscription activated',
		bodyOne:'Your Commercial subscription has been activated. The subscription will end on',
		bodyTwo: 'You and your group users now have access to all articles and tasting notes on the sites, and permission to publish scores and tasting notes according to the plan purchased.',
	},
]

function Checkout():ReactElement {
	const { search } = useLocation()
	const {type} = useParams<{ type: string }>()
	const queryParams = new URLSearchParams(search)
	const planType = queryParams.get('type')

	const personalPlans = ['personal-3-month', 'personal-1-year', 'personal-monthly']
	const commercialPlans = ['commercial-1-year', 'commercial-1-year-t2', 'commercial-1-year-t3']

	if(type === 'success'){
		if(planType === 'personal' || planType === 'commercial'){
			const content = planType === 'personal' ? bodyContent[0] : bodyContent[1]
			return <SuccessActivation header={content.header} bodyOne={content.bodyOne} bodyTwo={content.bodyTwo} />
		}
	}

	if(personalPlans.includes(type))
		return <PersonalCheckout />

	if(commercialPlans.includes(type))
		return <CommercialCheckout />

	return <NonExistPage />
}

export default Checkout
