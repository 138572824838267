/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { FieldValues, useForm } from 'react-hook-form'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TagManager from 'react-gtm-module'

import ProfileCard from '../components/component-cards/ProfileCard'
import PaymentCard from '../components/component-cards/PaymentCard'
import SubscriptionCard from '../components/component-cards/SubscriptionCard'
import AddOnCard from '../components/component-cards/AddOnCard'
import { useGetCustomerPaymentMethodQuery, useGetTradeDirectoryLookupCountriesQuery, useGetUserLoginMutation, useSignUpExistingUserUpdgradeCommercialMutation, useSignUpNewUserMutation } from '../../../../../app/services'
import { useAppSelector } from '../../../../../app/hooks'
import { Users as UsersFromStore} from '../../../../../app/slices/Users'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import NoticeModal from '../../../../../components/notice-modal'
import ErrorModal from '../../../../../components/error-modal'
import AddNewCard from '../../gift-subscription/gift-sub/components/AddNewCard'
import { isUserAuthAndExpired, obfuscateConstant, trimmedEmails } from '../../../../../utils/Constant'
import ModalContainer from '../../../../../components/modal-container/ModalContainer'
import { SaveAuthenticationSession } from '../../../../../utils'
import { UpdateUserAuthDetails } from '../../../../../app/slices/Authentication'
import { getCSURFToken } from '../../../../../utils/CSRFToken'
import { deviceType } from '../../../../../helper/constants'
import { setCookie } from '../../../../../utils/CookieUtils'
import { UpdateSuccessAnalytics } from '../../../../../app/slices/Analytics'
import { useGetPromoByCodeMutation } from '../../../../../app/services/Subscription'
import { PLAN_ANALYTICS } from '../../../../../configs'

import { GiftSubMainContainer, GiftSubTitle } from '../../gift-subscription/styles'
import { isSubscriptionExpired } from '../../../../../app/services/constant'
import { setAccessLocalStorage } from '../../../../../utils/ValidateAccessToken'

const plans = [
	{
		head: 'Commercial Annual',
		price: 249,
		planId: 'commercial-1-year'
	},
]

function CommercialCheckout(): ReactElement {
	const recaptchaRef = useRef(null)
	const { type } = useParams<{ type: string }>()
	const { userData } = useAppSelector(UsersFromStore)
	const history = useHistory()
	const dispatch = useDispatch()

	const [countries, setCountries] = useState<string[]>([])
	const [showAddModal, setShowAddModal] = useState(false)
	const [newCardDetails, setNewCardDetails] = useState<{ isNewCard: boolean, data: any }>({
		isNewCard: false,
		data: null
	})
	const [checkoutError, setCheckoutError] = useState({
		isError: false,
		message: ''
	})
	const [addonQty, setAddonQty] = useState(0)
	// const [addonNotes, setAddonNotes] = useState(0)
	const [total, setTotal] = useState(0)
	
	const { handleSubmit, getValues, setValue, setError, control, clearErrors, watch } = useForm()
	const { control: cardControl, handleSubmit: cardHandleSubmit, reset: cardReset } = useForm()

	const { data: countryData } = useGetTradeDirectoryLookupCountriesQuery('')
	const { data: paymentData, isLoading: paymentListLoading } = useGetCustomerPaymentMethodQuery(userData?._id, { skip: !userData?._id })
	const [userLoginMutation, { isLoading: userLoginLoading }] = useGetUserLoginMutation()
	const [signUpNewUserMutation, { isLoading: signUpLoading }] = useSignUpNewUserMutation()
	const [signUpExistingMutation, { isLoading: signUpExistingLoading }] = useSignUpExistingUserUpdgradeCommercialMutation()
	const [validateCodeMutation] = useGetPromoByCodeMutation()

	const planTypeData = plans.find(plan => plan.planId === type)
	const isLogged = userData ? true : false

	useEffect(() => {
		triggerSubscribe('onLoad', '')
	}, [type])
	
	useEffect(() => {
		if (countryData?.success) {
			const countryList = countryData.data.map((country: { _id: string; code: string; name: string; }) => country.name)
			setCountries(countryList)
		}
	}, [countryData])

	useEffect(() => {
		if(paymentData && paymentData.success){
			const paymentToken = paymentData?.data[0]?.token
			setValue('paymentMethodToken', paymentToken)
		}
	}, [paymentData])

	useEffect(() => {
		const addOn = watch('addon')
		const planData = plans.find(plan => plan.planId === type)

		if(addOn !== undefined || !planData){
			setTotal((addOn * 129) + (planData?.price || 0))
			setAddonQty(addOn)
		} else {
			setTotal(planData?.price || 0)
			setAddonQty(0)
		}
	}, [watch('addon')])	

	const loginUser = async (email: string, password: string) => {
		try{
			const csrf_token = await getCSURFToken()

			const loginPayload = {
				username: email,
				password: password,
				device: deviceType(),
			}
			const loginResultData = await userLoginMutation({params: loginPayload, token: csrf_token}).unwrap()

			if(!loginResultData.success){
				handleRecaptchaReset()
				return setCheckoutError({
					isError: true,
					message: loginResultData.message || loginResultData.data.message || JSON.stringify(loginResultData)
				})
			}
			
			SaveAuthenticationSession(
				'user',
				loginResultData.data.accessToken,
				loginResultData.data.accessTokenExpiresAt,
				loginResultData.data.refreshToken,
				loginResultData.data.refreshTokenExpiresAt,
				loginResultData.data.client._id,
				loginResultData.data.user._id
			)

			setAccessLocalStorage(loginResultData.data.accessToken, loginResultData.data.accessTokenExpiresAt, loginResultData.data.refreshToken, loginResultData.data.refreshTokenExpiresAt)
			
			dispatch(UpdateUserAuthDetails({
				token: loginResultData.data.accessToken,
				tokenExpiry: loginResultData.data.accessTokenExpiresAt,
				refreshToken: loginResultData.data.refreshToken,
				refreshTokenExpiry: loginResultData.data.refreshTokenExpiresAt,
				clientId: loginResultData.data.client._id,
				country: loginResultData.data.country,
				userId: loginResultData.data.user._id
			}))

			setCookie('username', getValues('email'), true)

			history.push('/sign-up/success?type=commercial')
			window.location.reload()

		} catch(error: any) {
			handleRecaptchaReset()
			return setCheckoutError({
				isError: true,
				message: error?.message || error?.data?.message || JSON.stringify(error)
			})
		}
	}

	// const getTastingPayload = () => {
	// 	const notesString = addonNotes.toString()
	
	// 	if (notesString.includes('3000')) {
	// 		return 'add-3000'
	// 	} else if (notesString.includes('2000')) {
	// 		return 'add-2000'
	// 	} else if (notesString.includes('1000')) {
	// 		return 'add-1000'
	// 	} else if (notesString.includes('500')) {
	// 		return 'add-500'
	// 	} else {
	// 		return 0
	// 	}
	// }

	const getAddons = (addon: any) => {
		const addonValue = addon?.replace('Qty: ', '')
		return Number(addonValue)
	}
	
	const onSubmitHandler = async (formData: FieldValues) => {
		if (!formData.recaptcha)
			return setError('isAggrement', {
				type: ' custom',
				message: 'Recaptcha token is required.'
			})

		const countrySelected = countryData.data.find((country: any) => country.name === formData.country)
		const promoCode = formData.promoCode || ''
		const normalizeEmail = trimmedEmails(formData.email)
		
		const commonPayload: any = {
			titile: '',
			email: normalizeEmail,
			password: formData.password,
			firstName: formData.firstName,
			lastName: formData.lastName,
			receive_newsletter: formData.isNewsLetter ? 1 : 0,
			receive_marketingletter: formData.isMarketting ? 1 : 0,
			promoCode: promoCode,
			companyName: formData.companyName,
			companyTypes: formData.businessType?.map((company: {value: string; label: string}) => company.value),
			addOns: formData.addon ? Number(formData.addon) : 0, 
			phone: formData.phone,
			fax: '',
			website: '',
			country: countrySelected,
			planId: type,
			recaptcha_token: formData.recaptcha,
		}

		let payload: any = {
			...commonPayload,
			card: {
				name: formData.cardHolderFirstName + ' ' + formData.cardHolderLastName,
				number: formData.cardNumber,
				expirationDate: formData.expiryMonth + '/' + formData.expiryYear,
				cvv: formData.cvv,
				postalCode: formData.zipCode,
				paymentMethodToken: ''
			}
		}

		if(isLogged && newCardDetails.isNewCard)
			payload = {
				...commonPayload,
				customerId: userData?._id,
				card: {
					name: newCardDetails.data.cardHolderFirstName + ' ' + newCardDetails.data.cardHolderLastName,
					number: newCardDetails.data.cardNumber,
					expirationDate: newCardDetails.data.expiryMonth + '/' + newCardDetails.data.expiryYear,
					cvv: newCardDetails.data.cvv,
					postalCode: newCardDetails.data.zipCode,
					paymentMethodToken: ''
				},

			}

		if(isLogged && !newCardDetails.isNewCard)
			payload = {
				...commonPayload,
				customerId: userData?._id,
				card: {
					name: '',
					number: '',
					expirationDate: '',
					cvv: '',
					postalCode: '',
					paymentMethodToken: formData.paymentMethodToken
				}
			}

		if(userData && (!paymentData || (paymentData && !paymentData.success) || (paymentData && paymentData.success && !paymentData.data.length))){
			payload = {
				...commonPayload,
				customerId: userData?._id,
				card: {
					name: formData.cardHolderFirstName + ' ' + formData.cardHolderLastName,
					number: formData.cardNumber,
					expirationDate: formData.expiryMonth + '/' + formData.expiryYear,
					cvv: formData.cvv,
					postalCode: formData.zipCode,
					paymentMethodToken: ''
				}
			}
		}
			
		try {
			const csrf_token = await getCSURFToken()

			if(isLogged){
				const signUpExistingUser = await signUpExistingMutation({ params: payload, token: csrf_token }).unwrap()

				if(!signUpExistingUser.success){
					setCheckoutError({
						isError: true,
						message: signUpExistingUser.message || signUpExistingUser.data.message || JSON.stringify(signUpExistingUser)
					})
					handleRecaptchaReset()
					return
				}

				if(signUpExistingUser.success){
					const subscription = signUpExistingUser.data
					const transactionid = subscription.transactions && subscription.transactions.length ? subscription.transactions[0].id : ''

					triggerSubscribe('onSave', transactionid)
					history.push('/sign-up/success?type=commercial')
					window.location.reload()
				}
				
			}

			const signUpNewUser = await signUpNewUserMutation({ params: payload, token: csrf_token }).unwrap()

			if(!signUpNewUser.success){
				handleRecaptchaReset()
				return setCheckoutError({
					isError: true,
					message: signUpNewUser.message || signUpNewUser.data.message  || JSON.stringify(signUpNewUser)
				})
			}

			if(signUpNewUser.success){
				const subscription = signUpNewUser.data.subscription
				const transactionid = subscription.transactions && subscription.transactions.length ? subscription.transactions[0].id : ''
				triggerSubscribe('onSave', transactionid)
				await loginUser(payload.email, payload.password)
			}

			await loginUser(payload.email, payload.password)
		} catch (error: any) {
			handleRecaptchaReset()
			return setCheckoutError({
				isError: true,
				message: error?.message || error?.data?.message || JSON.stringify(error)
			})
		}
	}

	const cardFormSubmit = (cardDetails: FieldValues) => {
		if(cardDetails){
			setNewCardDetails({
				data: cardDetails,
				isNewCard: true,
			})
			setShowAddModal(false)
			setValue('paymentMethodToken', '')
			cardReset()
		}
	}

	const handleRecaptchaReset = () => {
		if (recaptchaRef.current) {
			(recaptchaRef.current as any).reset()
		}
	}

	const triggerSubscribe = async (triggerType: 'onLoad' | 'onSave', transactId: string) => {

		if(triggerType === 'onLoad'){
			const selectedPlan = PLAN_ANALYTICS.find(plan => plan.planId === type)

			if(selectedPlan){
				const event = {
					event: 'begin_checkout',
					ecommerce: {
						currency: 'USD',
						value: selectedPlan.originalPrice,
						items: [
							{
								index: 0,
								recommended_plan: selectedPlan.recommended,
								item_list_id: selectedPlan.item_list_id,
								item_list_name: selectedPlan.item_list_name,
								item_brand: selectedPlan.item_brand,
								item_name: selectedPlan.item_name,
								item_category: selectedPlan.item_category,
								item_category2: selectedPlan.item_category2,
								coupon: selectedPlan.coupon,
								discount: undefined,
								price: selectedPlan.originalPrice
							}
						]
					}
				}

				// console.log(`event: ${type}`, event)

				TagManager.dataLayer({
					dataLayer: {
						ecommerce: null
					}
				})
	
				TagManager.dataLayer({
					dataLayer: event
				})
			}
		}

		if(triggerType === 'onSave'){

			const seatsNumber = getAddons(getValues('addon')) || 0
			// const tastingNote = getValues('addonNotes') ? getTastingPayload() : ''

			const promoCode = getValues('promoCode')
			let discountValue = 0

			if(promoCode){
				const promoDiscountValue = await validateCodeMutation({promoCode}).unwrap()

				if(promoDiscountValue.success)
					discountValue = promoDiscountValue.data.discountValue
			}

			dispatch(UpdateSuccessAnalytics({
				planId: type,
				seats: seatsNumber,
				tasting_notes: '',
				discountValue,
				transactionId: transactId,
				discountCoupon: obfuscateConstant(promoCode)
			}))
		}
	}

	const showBlockSubscriptionModal = () => {
		if(userData){
			if(userData.orbit_roles.includes('project:customer-commercial'))
				return true

			if (!userData.subscription)
				return false

			const isExpired = isSubscriptionExpired(userData.subscription.end)

			if (!isExpired) {
				return true
			}
		}
		return false
	}

	return <>
		{userLoginLoading && <LoadingOverlay />}
		{signUpLoading && <LoadingOverlay />}
		{signUpExistingLoading && <LoadingOverlay />}
		{paymentListLoading && <LoadingOverlay />}
		<div className="single-entry">
			<div className="container" style={{ padding: isMobile ? 0 : 15 }}>
				<GiftSubMainContainer isMobile={isMobile}>
					<GiftSubTitle isMobile={isMobile}>
						<p>Checkout - $249</p>
					</GiftSubTitle>
					<form className='gift-grid-container' onSubmit={handleSubmit(onSubmitHandler)}>
						<div className='gift-grid'>
							<ProfileCard isCommercial={true} control={control} isLogged={userData?._id ? true : false} countries={countries} getValues={getValues} headerText='1. Company Information' userData={userData} setValue={setValue}/>
							<PaymentCard control={control} isLogged={userData?._id ? true : false} headerText='2. Payment Method' paymentData={paymentData} onAddNewCard={() => setShowAddModal(true)} newCardDetails={newCardDetails} />
							<AddOnCard control={control} headerText='Would you like any add-ons?'/>
						</div>
						<div className="gift-grid">
							<SubscriptionCard control={control} recaptchaRef={recaptchaRef} setValue={setValue} getValues={getValues} headerText='3. Subscription' setError={setError} total={total} clearErrors={clearErrors} aggreementText='Commercial' isLogged={userData?._id ? true : false}>
								<div className='text-field' style={{ marginBottom: '1rem' }}>
									<span style={{ fontWeight: 'normal' }}>{planTypeData?.head}</span>
									<span style={{ fontWeight: 'normal' }}>${planTypeData?.price}</span>
								</div>
								<div className='text-field' style={{ marginBottom: '1rem' }}>
									<span style={{ fontWeight: 'normal' }}>Additional Seats Qty: {addonQty}</span>
									<span style={{ fontWeight: 'normal' }}>${addonQty*129}</span>
								</div>
							</SubscriptionCard>
						</div>
					</form>
				</GiftSubMainContainer>
			</div>
			{ checkoutError.isError && <ErrorModal messageBody={checkoutError.message} isOpen={checkoutError.isError} onClose={() => setCheckoutError({ isError: false, message: '' })} bgColor='#73333F' closeTextColor='white' /> }
			{showBlockSubscriptionModal() &&
				<NoticeModal
					messageBody={`You currently have an active subscription that ends on ${moment(new Date(userData?.subscription?.end)).format('MMMM DD, YYYY')}. You can manage renewal of your subscription in Manage subscription.`}
					title={'NOTICE MESSAGE'}
					isOpen={isUserAuthAndExpired(userData)} onClose={() => history.push('/my-subscription')} isBlocked={true}/>}
			<ModalContainer isOpen={showAddModal} onClose={() => setShowAddModal(false)}>
				<AddNewCard control={cardControl} handleSubmit={cardHandleSubmit} onSubmit={cardFormSubmit} onClose={() => setShowAddModal(false)} />
			</ModalContainer>
		</div>
	</>
}

export default CommercialCheckout
