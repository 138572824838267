/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect } from 'react'
import packgeJSON from '../package.json'
console.log(`APP VERSION: ${packgeJSON.version}`)

// Styles
import './styles/css/style.css'
import 'rc-slider/assets/index.css'

// Site sections
import Header from './views/header'
import Content from './views/content'
import Footer from './views/footer'
import Modals from './views/modals'
import { BrowserRouter } from 'react-router-dom'

// Utilities and Helpers
import { ClearUserSession, IsUserAuthenticated, IsUserAuthenticationExpired } from './utils'

// Tools
import { useDispatch } from 'react-redux'
// import TagManager from 'react-gtm-module'
import { useAppSelector } from './app/hooks'
import { Authentication as AuthFromStore, UpdateUserAuthDetails } from './app/slices/Authentication'
import { APP_CONFIG } from './configs'
import { getCookie } from './utils/CookieUtils'

// Styles
import './styles/css/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import {CloudimageProvider} from 'react-cloudimage-responsive'
import ErrorBoundary from './views/misc/ErrourBoundary'

function App(): ReactElement {
	const dispatch = useDispatch()

	const authentication = useAppSelector(AuthFromStore)

	const getUserAuthenticationPayload = () => {

		if (IsUserAuthenticated()) {
			const sessionData = getCookie(APP_CONFIG.AUTHENTICATION.SESSION_LABEL)

			const userData = {
				token: sessionData.token ? sessionData.token : '',
				tokenExpiry: sessionData.tokenExpiry ? sessionData.tokenExpiry : '',
				refreshToken: sessionData.refreshToken ? sessionData.refreshToken : '',
				refreshTokenExpiresAt: sessionData.refreshTokenExpiry ? sessionData.refreshTokenExpiry : '',
				clientId: sessionData.clientId ? sessionData.clientId : '',
				userId: sessionData.userId ? sessionData.userId : '',
				country: sessionData.country ? sessionData.country : ''
			}
			return userData
		}
		return {}
	}

	useEffect(() => {
		if (IsUserAuthenticated() && !IsUserAuthenticationExpired() && !authentication.User.accessToken) {
			dispatch(UpdateUserAuthDetails(getUserAuthenticationPayload()))
		} else if (IsUserAuthenticationExpired()) {
			ClearUserSession(dispatch)
		}
	}, [dispatch])
	
	// useEffect(() => {
	// 	TagManager.dataLayer({
	// 		dataLayer: {
	// 			'isSignedIn': IsUserAuthenticated(),
	// 			'isBusiness': false,
	// 			'isOptin': true
	// 		}
	// 	})

	// }, [])
	
	type CloudimageConfig = {
		token: string;
		baseURL: string;
		presets: any;
	}
	
	const cloudimageConfig: CloudimageConfig = {
		token: 'cexuavomia',
		baseURL: 'https://cexuavomia.cloudimg.io/',

		presets: {
			xs: '(max-width: 575px)',
			sm: '(min-width: 576px)',
			md: '(min-width: 768px)',
			lg: '(min-width: 992px)',
			xl: '(min-width: 1200px)'
		}
	}

	return (
		<>
			<CloudimageProvider config={cloudimageConfig}>
				<BrowserRouter>
					<Header />
					<ErrorBoundary>
						<Content />
					</ErrorBoundary>
					<br />
					<br />
					<Footer />
					<Modals />
				</BrowserRouter>
			</CloudimageProvider>
		</>
	)
}

export default App
