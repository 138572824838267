/* eslint-disable indent */
import React, { ReactElement, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { CSSTransition } from 'react-transition-group'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { FEATURES } from '../../../../../configs'
import { isMobile } from 'react-device-detect'
import { FeaturedCommercialTitle, FeaturedDatalist, FeaturedTitleWrapper, FeaturedWrapper, FullCommercialContainer, FullFeaturedContainer, ListDataWrapper, ListFeaturedContainer, Seperator, SubscriptionCardContainer, SubscriptionHeader, SubscriptionSecondHeader } from '../styles'
import PlanTypes from '../PlanTypes'
import { FadeInAnimation } from '../../../../../styles/CSSTransition'
import {Users as UsersFromStore } from '../../../../../app/slices/Users'

function SubscriptionsOffers(): ReactElement {
	const { type } = useParams<{ type: string }>()
	const { userData } = useSelector(UsersFromStore)

	useEffect(() => {
		if(type){
			TagManager.dataLayer({
				dataLayer: {
					ecommerce: null
				}
			})

			if(type === 'personal-offers'){
				const datalayer = {
					dataLayer: {
						event: 'view_item_list',
						ecommerce: {
							currency: 'USD',
							item_list_id: 'rpwa_personals_plans',
							item_list_name: 'RPWA personals plans',
							items: [
									{
									index: 0,
									recommended_plan: false,
									item_brand: 'Personal subscription',
									item_name: 'Monthly personal subscription',
									price: 15
								},
								{
									index: 1,
									recommended_plan: true,
									item_brand: 'Personal subscription',
									item_name: 'Annual personal subscription',
									price: 11
								},
								{
									index: 2,
									recommended_plan: false,
									item_brand: 'Personal subscription',
									item_name: 'Quaterly personal subscription',
									price: 12.3
								}
							]
						}
					}
				}

				// console.log(`datalayer: ${type}`, datalayer)

				TagManager.dataLayer(datalayer)
			}
				

			if(type === 'commercial-offers'){
				const datalayer = {
					dataLayer: {
						event: 'view_item_list',
						ecommerce: {
							currency: 'USD',
							item_list_id: 'rpwa_commercials_plans',
							item_list_name: 'RPWA commercials plans',
							items: [
									{
									index: 0, // integer type. Index/position of the plan on the list.
									recommended_plan: true, // boolean
									item_brand: 'Commercial subscription',
									item_name: 'Commercial Basic',
									price: 249 // number type
								}
								]
							// items: [
							// 		{
							// 		index: 0,
							// 		recommended_plan: false,
							// 		item_brand: 'Commercial subscription',
							// 		item_name: 'I Consult',
							// 		price: 249
							// 	},
							// 	{
							// 		index: 1,
							// 		recommended_plan: true,
							// 		item_brand: 'Commercial subscription',
							// 		item_name: 'I Guide',
							// 		price: 500
							// 	},
							// 	{
							// 		index: 2,
							// 		recommended_plan: false,
							// 		item_brand: 'Commercial subscription',
							// 		item_name: 'I Promote',
							// 		price: 2000
							// 	}
							// ]
						}
					}
				}

				// console.log(`datalayer: ${type}`, datalayer)

				TagManager.dataLayer(datalayer)
			}
		}
	}, [type])
	

	return (
		<div className="single-entry">
			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<div className="container">
					<SubscriptionHeader>Choose the plan that’s right for you.</SubscriptionHeader>
				</div>
				<div className="container">
					<SubscriptionCardContainer>
						<CSSTransition
							in={true}
							appear={true}
							timeout={1500}
						>
							<FadeInAnimation>
								<PlanTypes isloggedIn={userData?._id ? true : false}  />
							</FadeInAnimation>
						</CSSTransition>
					</SubscriptionCardContainer>
					<Seperator></Seperator>
					<div className="container">
						<SubscriptionSecondHeader>
							{type === 'personal-offers' ? 
								<h2>More about what to expect with your Robert Parker subscription</h2>
								:
								<>
									<h2>What to expect with your Commercial subscription</h2>
									<p>Check this space for more Commercial options in near future!</p>
								</>
							}
						</SubscriptionSecondHeader>
					</div>

				</div>
			</div>

			<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
				<div className="container" style={{ paddingLeft: isMobile ? 0 : 15, paddingRight:  isMobile ? 0 : 15  }}>
					{type === 'personal-offers' ? 
						<FeaturedWrapper isMobile={isMobile}>
							{FEATURES.map((feature, key) => (
								<div className='p-flex' key={key}>
									{(feature.left)? (
										<FullFeaturedContainer withBackground={key % 2 === 0} hasBorderBtm={true}>
											<div>
												<p>{feature.label}</p>
											</div>
										</FullFeaturedContainer>
									) : null}
								</div>
							))}
							<div className='p-flex'>
									<FullFeaturedContainer withBackground={true} hasBorderBtm={false}>
										<div>
											<p>10% of any RPWA events this year</p>
										</div>
									</FullFeaturedContainer>
							</div>
						</FeaturedWrapper>
						: 
						type === 'commercial-offers' &&
							<FeaturedWrapper isMobile={isMobile}>
								<FeaturedTitleWrapper>
									<FeaturedCommercialTitle hasBorderRadiusLeft={isMobile ? true : false} hasBorderLeft={isMobile ? false : true}><p>Commercial Basic</p></FeaturedCommercialTitle>
									{/* <FeaturedCommercialTitle isGold={true} hasBorderRight={true}  hasBorderLeft={true}><p>I Guide (Recommended)</p></FeaturedCommercialTitle>
									<FeaturedCommercialTitle hasBorderRadiusRight={true}><p>I Promote</p></FeaturedCommercialTitle> */}
								</FeaturedTitleWrapper>
								<div className='p-flex'>
									{ !isMobile && <div className='sub-header-temp' />}
									<FullCommercialContainer><p>Unlimited Reading and Consultations</p></FullCommercialContainer>
								</div>

								<ListDataWrapper hasBorderBottom={true}>
									<ListFeaturedContainer withBackground={isMobile ? true : false} hasBorderBtm={isMobile ? true : false}>Exclusive In-Depth Articles by Global wine experts, offering more than just reviews.</ListFeaturedContainer>
									<div className='data-value'>
										<FeaturedDatalist hasBorderLeft={false} isCheck={true} hasBorderRight={true}><p>&#10003;</p></FeaturedDatalist>
										{/* <FeaturedDatalist isCheck={true} isGold={true} hasBorderRight={true}><p>&#10003;</p></FeaturedDatalist>
										<FeaturedDatalist hasBorderRadius={false} isCheck={true}><p>&#10003;</p> </FeaturedDatalist> */}
									</div>
								</ListDataWrapper>

								<ListDataWrapper hasBorderBottom={true}>
									<ListFeaturedContainer withBackground={true} hasBorderBtm={isMobile ? true : false}>Access to over 450,000 Tasting Notes, Covering Regions all over the World</ListFeaturedContainer>
									<div className='data-value'>
										<FeaturedDatalist hasBorderLeft={false} isCheck={true} hasBackgroundColor={isMobile ? false : true} hasBorderRight={true}><p>&#10003;</p></FeaturedDatalist>
										{/* <FeaturedDatalist isCheck={true} isGold={true} hasBackgroundColor={false} hasBorderRight={true}><p>&#10003;</p></FeaturedDatalist>
										<FeaturedDatalist hasBorderRadius={false} isCheck={true} hasBackgroundColor={isMobile ? false : true}><p>&#10003;</p> </FeaturedDatalist> */}
									</div>
								</ListDataWrapper>

								<ListDataWrapper hasBorderBottom={false}>
									<ListFeaturedContainer withBackground={isMobile ? true : false} hasBorderBtm={isMobile ? true : false}>Wine Vintage Charts Dating Back to the 1970s</ListFeaturedContainer>
									<div className='data-value'>
										<FeaturedDatalist hasBorderLeft={false} isCheck={true} hasBorderRight={true}><p>&#10003;</p></FeaturedDatalist>
										{/* <FeaturedDatalist isCheck={true} isGold={true} hasBorderRight={true}><p>&#10003;</p></FeaturedDatalist>
										<FeaturedDatalist hasBorderRadius={false} isCheck={true}><p>&#10003;</p> </FeaturedDatalist> */}
									</div>
								</ListDataWrapper>

								<div className='p-flex'>
									{ !isMobile && <div className='sub-header-temp' />}
									<FullCommercialContainer><p>Right to Publish and Print Up To</p></FullCommercialContainer>
								</div>

								<ListDataWrapper hasBorderBottom={true}>
									<ListFeaturedContainer withBackground={true} hasBorderBtm={isMobile ? true : false}>Scores on the Website</ListFeaturedContainer>
									<div className='data-value'>
										<FeaturedDatalist hasBorderLeft={false} hasBackgroundColor={isMobile ? false : true} hasBorderRight={true}><p>200</p></FeaturedDatalist>
										{/* <FeaturedDatalist isGold={true} hasBorderRight={true}><p>xxx - xxx</p></FeaturedDatalist>
										<FeaturedDatalist hasBorderRadius={false} hasBackgroundColor={isMobile ? false : true}><p>xx - xx</p> </FeaturedDatalist> */}
									</div>
								</ListDataWrapper>

								<ListDataWrapper hasBorderBottom={true}>
									<ListFeaturedContainer withBackground={isMobile ? true : false} hasBorderBtm={isMobile ? true : false}>Line Reviews with Redirection to RPWA Page on Website</ListFeaturedContainer>
									<div className='data-value'>
										<FeaturedDatalist hasBorderLeft={false} hasBorderRight={true}><p>200</p></FeaturedDatalist>
										{/* <FeaturedDatalist isGold={true} hasBorderRight={true}><p>xxx - xxx</p></FeaturedDatalist>
										<FeaturedDatalist hasBorderRadius={false}><p>xxxx - xxxx</p> </FeaturedDatalist> */}
									</div>
								</ListDataWrapper>

								<ListDataWrapper hasBorderBottom={false}>
									<ListFeaturedContainer withBackground={true} hasBorderBtm={isMobile ? true : false}>Drinking Windows</ListFeaturedContainer>
									<div className='data-value'>
										<FeaturedDatalist hasBorderLeft={false} hasBackgroundColor={isMobile ? false : true} hasBorderRight={true}><p>200</p></FeaturedDatalist>
										{/* <FeaturedDatalist isGold={true} hasBorderRight={true}><p>xxx - xxx</p></FeaturedDatalist>
										<FeaturedDatalist hasBorderRadius={false} hasBackgroundColor={isMobile ? false : true}><p>xxxx - xxxx</p> </FeaturedDatalist> */}
									</div>
								</ListDataWrapper>
								{/* 
									{ !isMobile && <div className='sub-header-temp' />}
									<FullCommercialContainer><p>Optional Add-Ons</p></FullCommercialContainer>
								</div>

								<ListDataWrapper hasBorderBottom={true}>
									<ListFeaturedContainer withBackground={isMobile ? true : false} hasBorderBtm={isMobile ? true : false}>Additional Seats</ListFeaturedContainer>
									<div className='data-value'>
										<FeaturedDatalist hasBorderLeft={false} hasBackgroundColor={false} isDetailed={true} hasBorderRight={true}><p>2 ($129/Seat)</p></FeaturedDatalist>
										<FeaturedDatalist isGold={true} isDetailed={true} hasBorderRight={true}><p>4 ($129/Seat)</p></FeaturedDatalist>
										<FeaturedDatalist hasBorderRadius={false} hasBackgroundColor={false} isDetailed={true}><p>40 ($129/Seat)</p> </FeaturedDatalist>
									</div>
								</ListDataWrapper>

								<ListDataWrapper hasBorderBottom={true}>
									<ListFeaturedContainer withBackground={true} hasBorderBtm={isMobile ? true : false}>Additional Tasting Notes</ListFeaturedContainer>
									<div className='data-value'>
										<FeaturedDatalist hasBorderLeft={false} hasBackgroundColor={isMobile ? false : true} hasBorderRight={true}><p></p></FeaturedDatalist>
										<FeaturedDatalist isGold={true} isDetailed={true} hasBorderRight={true}><p>500 ($800)</p></FeaturedDatalist>
										<FeaturedDatalist hasBorderRadius={false} hasBackgroundColor={isMobile ? false : true} isDetailed={true}><p>1000 - 3000 ($1500 - $3000)</p> </FeaturedDatalist>
									</div>
								</ListDataWrapper>

								<ListDataWrapper hasBorderBottom={true}>
									<ListFeaturedContainer withBackground={isMobile ? true : false} hasBorderBtm={isMobile ? true : false}>Notes via Liv-ex API</ListFeaturedContainer>
									<div className='data-value'>
										<FeaturedDatalist hasBorderLeft={false} hasBackgroundColor={false} hasBorderRight={true}><p></p></FeaturedDatalist>
										<FeaturedDatalist isGold={true} hasBackgroundColor={false} hasBorderRight={true}><p></p></FeaturedDatalist>
										<FeaturedDatalist hasBorderRadius={false} hasBackgroundColor={false} isDetailed={true}><p>$500</p> </FeaturedDatalist>
									</div>
								</ListDataWrapper>

								<ListDataWrapper hasBorderBottom={false}>
									<ListFeaturedContainer withBackground={true} hasBorderBtm={isMobile ? true : false}>Robert Parker Marketing Usage</ListFeaturedContainer>
									<div className='data-value'>
										<FeaturedDatalist hasBorderLeft={false} hasBackgroundColor={isMobile ? false : true} hasBorderRight={true} hasBorderRadiusLeft={isMobile ? true : false}><p></p></FeaturedDatalist>
										<FeaturedDatalist isGold={true} hasBorderRight={true}><p></p></FeaturedDatalist>
										<FeaturedDatalist hasBorderRadius={false} hasBackgroundColor={isMobile ? false : true} isDetailed={true} hasBorderRadiusRight={isMobile ? true : false}><p>Contact Us</p> </FeaturedDatalist>
									</div>
								</ListDataWrapper> */}

							</FeaturedWrapper>
					}
				</div>
			</div>
		</div>
	)
}
export default SubscriptionsOffers