/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// Styles
import { slugify } from '../../../../../utils/ArticlesUtil'
import { ArticleContainer, ArticleImageContainer } from './styles'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import { CloudImageUtils } from '../../../../../utils/CloudImageUtils'
import useScreen from '../../../../../helper/hooks/useScreen'
import Pagination from '../../../../../components/pagination'
import { useGetWineJournalArticlesQuery, useGetListofAuthorsQuery } from '../../../../../app/services/Article'

type AuthorProps = {
	_id: number | string;
	first_name: string;
	last_name: string;
}

function FreePubsWineJournal(): ReactElement {

	const [offset, setOffset] = useState(0)
	const [authorId, setAuthorId] = useState('')
	
	const limit = 10
	
	const {data: articleData, isLoading, isFetching} = useGetWineJournalArticlesQuery({offset, limit, authorId})
	const {data: authorsList, isLoading: authorsListLoading} = useGetListofAuthorsQuery()

	const [data, setData] = useState<any>()
	const [authors, setAuthors] = useState<AuthorProps[]>([])
	
	const screen = useScreen()
	const [totalPagination, setTotalPagination] = useState(10)
	const imageRatio = () => {
		switch (screen) {
		case 'tablet portrait':
			return 1.1
		case 'desktop':
			return 1.3
		default:
			return 3.5
		}
	}

	const handleChangePage = (page: number) => {
		setOffset(page)

		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

	useEffect(() => {
		if(articleData && articleData?.success){
			const totalRecords = articleData?.data?.meta?.total
			setTotalPagination(totalRecords / limit)
			setData(articleData.data)
			
		}
	}, [articleData])

	useEffect(() => {
		if(authorsList && authorsList?.success)
			setAuthors(authorsList.data)
	}, [authorsList])
	
	return (
		<div>
			{isLoading && <LoadingOverlay />}
			{isFetching && <LoadingOverlay />}
			<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background">
				<div className="container">
					{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>Wine Journal</h2> : <h2 className="page-heading__title">Wine Journal</h2>}
					{data?.articles ? (
						<div className='page-heading__subtitle'>{articleData?.data?.meta?.total} articles</div>
					) : null}
				</div>
			</div>
			<div className="mnmd-block mnmd-block--fullwidth">
				<div className='container'>
					<div className="mnmd-block mnmd-block--fullwidth">
						<div className="container">
							{!authorsListLoading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2rem' }}>
								<div/>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
									<span style={{ fontWeight: 800, width: '9rem' }}>BY AUTHOR</span>
									<select className='component-design' onChange={(e) => setAuthorId(e.target.value)}>
										<option value=''>All</option>
										{authors.map((author, idx) => <option key={idx} value={author._id}>{author.first_name} {author.last_name}</option>)}
									</select>
								</div>
							</div>}
							{data?.articles && data.articles.map((article: any, key: number) => (
								<ArticleContainer key={key} >
									<ArticleImageContainer>
										<Link className='linktitle' to={`/articles/${article.id}/${slugify(article.title)}`}>{article.image?.url ? <CloudImageUtils imgSrc={article.image?.url ?? '/img/icons/default_article_banner.jpg'} alt="image" ratio={imageRatio()} params='func=cropfit'/> : <img src="/img/icons/default_article_banner.jpg" alt="image" /> }   </Link>
									</ArticleImageContainer>
									<div className='details-container'>
										<div className='title'>
											<Link className='linktitle' to={`/articles/${article.id}/${slugify(article.title)}`}>{article.title}</Link>
										</div>
										<div className='excerpt'>{article.excerpt}</div>
										<div className='filter-category'>{article.category.name}</div>
										<div className="post__meta details" style={{ width: '100%' }}>
											<span className="entry-author">By:&nbsp;
												<span className="entry-author__name">{article.author.name}</span>
											</span>
											<time className="time published detail-container" dateTime={article.published_date} title={moment(article.published_date).format('MMM DD, YYYY')}>
												<i className="mdicon mdicon-schedule"></i>
												<span className='date'>{moment(article.published_date).format('MMM DD, YYYY')}</span>
											</time>
											<Link to={`/search/wines?&article_id=${article.id}`} title={`${article.tasting_note_count} tasting notes`}><i className="mdicon mdicon-chat_bubble_outline"></i>{article.tasting_note_count || 0}</Link>
										</div>
									</div>
								</ArticleContainer>
							))}
							<Pagination totalRecords={totalPagination} limit={1} render={handleChangePage} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FreePubsWineJournal