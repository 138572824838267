/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState, FormEvent } from 'react'

// Slices
import { Authentication as AuthFromStore, UpdateUserAuthDetails } from '../../../app/slices/Authentication'

import { useAppSelector } from '../../../app/hooks'

import { SaveAuthenticationSession } from '../../../utils'
import { useDispatch } from 'react-redux'
import { deviceType } from '../../../helper/constants'
import { AuthExpiredContainer, ForgotPasswordContainer, SubmitActionContainer } from './styles'
import { useGetUserLoginMutation } from '../../../app/services'
import { getCSURFToken } from '../../../utils/CSRFToken'
import { dataCySelector } from '../../../app/services/constant'
import { setAccessLocalStorage } from '../../../utils/ValidateAccessToken'

function LoginForm(props: { closeWindow: any, handleError: any, setIsShown: (value: boolean) => void, isShown: boolean }): ReactElement {
	const dispatch = useDispatch()
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [isRemembered, setIsRemembered] = useState(false)
	const [loading, setLoading] = useState(false)
	const [errors, setErrors] = useState<any>(undefined)
	const [userNameError, setUserNameError] = useState<any>()
	const [passwordError, setPasswordError] = useState<any>()
	const [isAuthKeyExpired, setIsAuthKeyExpired] = useState(false)

	// const reCaptchaRef = useRef<any>()

	const { closeWindow, handleError, setIsShown, isShown } = props

	const authentication = useAppSelector(AuthFromStore)
	const [showPassword, setShowPassword] = useState(false)

	const errorIcon = (
		<img
			style={{ width: '12px' }}
			src={'img/icons/icon_error_warning.svg'}
			alt="Error Icon"
		/>
	)

	const getQueryParam = (name: string) => {
		const params = new URLSearchParams(window.location.search)
		return params.get(name)
	}
	
	useEffect(() => {
		const authExpired = getQueryParam('AuthExpired')
		if(authExpired === 'true'){
			setIsAuthKeyExpired(true)
		}
	},[])


	const [userLoginMutation, { data, isLoading: loginUserLoading, isError }] = useGetUserLoginMutation()

	const handleLogin = async (event: FormEvent<HTMLElement>) => {
		setErrors(undefined)
		handleError(undefined)
		event.preventDefault()

		if (!username && !password) {
			setUserNameError(<React.Fragment>{errorIcon} Email is required</React.Fragment>)
			setPasswordError(<React.Fragment>{errorIcon} Password is required</React.Fragment>)
			return
		} else if (!username) {
			setUserNameError(<React.Fragment>{errorIcon} Email is required</React.Fragment>)
			return
		} else if (!password) {
			setPasswordError(<React.Fragment>{errorIcon} Password is required</React.Fragment>)
			return
		}

		try {
			const csrf_token = await getCSURFToken()
			const normalizedUsername = username.toLowerCase()

			const loginPayload = {
				'username': normalizedUsername,
				'password': password,
				device: deviceType(),
				// recaptcha_token: token
			}
			await userLoginMutation({params: loginPayload, token: csrf_token})
			
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		setLoading(false)
		setUserNameError('')
		setPasswordError('')
		if (!authentication.Error && authentication?.User?.accessToken) {
			closeWindow()
		}
	}, [authentication])

	const handleUserNameChange = (event: any) => {
		setUsername(event.target.value)
		setErrors(undefined)
		handleError(undefined)
		setUserNameError('')
	}

	const handlePasswordChange = (event: any) => {
		setPassword(event.target.value)
		setErrors(undefined)
		handleError(undefined)
		setPasswordError('')
	}

	const handleTogglePassword = () => {
		setShowPassword(!showPassword)
	}

	const [isLoginTab, setIsLoginTab] = useState(true)

	useEffect(() => {
		setIsLoginTab(isShown)
	},[isShown])

	useEffect(() => {
		if(data){
			if(data.success){
				SaveAuthenticationSession(
					'user',
					data.data.accessToken,
					data.data.accessTokenExpiresAt,
					data.data.refreshToken,
					data.data.refreshTokenExpiresAt,
					data.data.client._id,
					data.data.user._id,
					data.data.user.country
				)

				setAccessLocalStorage(data.data.accessToken, data.data.accessTokenExpiresAt, data.data.refreshToken, data.data.refreshTokenExpiresAt)

				dispatch(UpdateUserAuthDetails({
					token: data.data.accessToken,
					tokenExpiry: data.data.accessTokenExpiresAt,
					refreshToken: data.data.refreshToken,
					refreshTokenExpiry: data.data.refreshTokenExpiresAt,
					clientId: data.data.client._id,
					country: data.data.country,
					userId: data.data.user._id
				}))
			} else {
				if(data.message ==='Invalid password.' || data.message === 'User not found.'){
					return handleError('Invalid email and/or password.')
				}

				return handleError(data.message)
			}
		}
		
		if(isError){
			handleError('Something went wrong. Please try again.')
		}	
	},[data, isError])

	return (
		<>
			<div role="tabpanel" className={`tab-pane fade ${isLoginTab && 'show active'}`} id="login-tab">
				{isAuthKeyExpired && 
				<AuthExpiredContainer>
					<p>Authorization has expired. Please log in again.</p>
				</AuthExpiredContainer>}
				<form name="loginform" id="loginform" action="#" method="post" onSubmit={handleLogin}>
					{loginUserLoading || loading ? (<p>Logging in ...</p>) : (<></>)}
					<p className="login-username">
						<label htmlFor="user_login">Email</label>
						<input type="text" name="log" id="user_login" style={{ borderColor: errors === 'User not found' || userNameError !== '' ? '#e01a00' : '#CCCCCC' }} className="input component-design" value={username} size={20} onChange={(event) => handleUserNameChange(event)} {...dataCySelector('email-input-field')}/>
					</p>
					<p style={{ color: '#e01a00', fontSize: '12px' }}>{username === '' ? userNameError : errors === 'User not found' ? <><img style={{ width: '12px' }} src={'img/icons/icon_error_warning.svg'} />&nbsp;{errors}</> : ''}</p>
					<p className="login-password">
						<label htmlFor="user_pass">Password</label>
						<input type={showPassword ? 'text' : 'password'} name="pwd" id="user_pass" style={{ borderColor: errors === 'Invalid Password' || passwordError !== '' ? '#e01a00' : '#CCCCCC' }} className="input component-design" value={password} size={20} onChange={(event) => handlePasswordChange(event)} {...dataCySelector('password-input-field')}/>
						<span className="eye-toggle-password-icon" onClick={() => handleTogglePassword()}>{password.length === 0 ? '' : showPassword ? <span className="material-symbols-outlined">visibility_off</span> : <span className="material-symbols-outlined">visibility</span>}</span>
					</p>
					<p style={{ color: '#e01a00', fontSize: '12px' }}>{password === '' ? passwordError : errors === 'Invalid Password' ? <><img style={{ width: '12px' }} src={'img/icons/icon_error_warning.svg'} />&nbsp;{errors}</> : ''}</p>
					<p className="login-remember">
						<label><input {...dataCySelector('remember-me-checkbox')} name="rememberme" type="checkbox" id="rememberme" value="forever" checked={isRemembered} onChange={(event) => { setIsRemembered(event.target.checked) }} /> Remember Me</label>
					</p>
					<SubmitActionContainer className="login-submit">
						<div>
							<input type="submit" name="submit-login" id="submit-login" className="btn btn-block btn-primary" aria-label="Close" value="Log In" {...dataCySelector('login-user-btn')}/>
						</div> 
						{/* {!isMobile ?
							<div>
								<input type="button" name="submit-login" id="submit-login" className="btn btn-block btn-primary g-recaptcha" aria-label="Close" value="Log In" data-sitekey={RP_ENV.INVISIBLE_RECAPTCHA_KEY} onClick={handleLogin} />
							</div> 
							:
							<a href="#mnmd-offcanvas-primary" className="btn btn-block btn-primary js-mnmd-offcanvas-close g-recaptcha" aria-label="Close" data-sitekey={RP_ENV.CAPTCHA_KEY} onClick={handleLogin}>
								LOG IN
							</a>
						} */}
						<input type="hidden" name="redirect_to" value="#" />
					</SubmitActionContainer>
					<ForgotPasswordContainer>
						<a onClick={() => setIsShown(false)} id='loginAnchor' {...dataCySelector('lost-your-password-btn')}>Lost your password</a>
					</ForgotPasswordContainer>
					{/* <ReCAPTCHA
						style={{ visibility: 'hidden' }}
						size='invisible'
						sitekey={`${reverseObfuscateConstant(RP_ENV.INVISIBLE_RECAPTCHA_KEY)}`}
						ref={reCaptchaRef}
					/> */}
				</form>
				
			</div>
		</>
	)

}

export default LoginForm