
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RP_ENV } from '../configs'
import { reverseObfuscateConstant } from './Constant'

export const ValidateAccessToken = async (accessToken: string | null, userId: string | null) => {
	try {
		// const csrf_token = await getCSURFToken()
		const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/user/validate-access-token`
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + accessToken,
				'x-api-key': RP_ENV.API_KEY,
				'XSRF-TOKEN': 'csrf_token'
			},

			body: JSON.stringify({
				userId: userId
			})
		})
		const validatedToken = await response.json()
		return validatedToken.data

	} catch (error) {
		console.log(error)
	}
}

export const removeAccessLocalStorage = () => {
	localStorage.removeItem('accessToken')
	localStorage.removeItem('accessTokenExpiresAt')
	localStorage.removeItem('refreshToken')
	localStorage.removeItem('refreshTokenExpiresAt')
}

export const setAccessLocalStorage = (accessToken: string, accessTokenExpiration: string, refreshToken: string, refreshTokenExpiration: string) => {
	localStorage.setItem('accessToken', accessToken)
	localStorage.setItem('accessTokenExpiresAt', accessTokenExpiration)
	localStorage.setItem('refreshToken', refreshToken)
	localStorage.setItem('refreshTokenExpiresAt', refreshTokenExpiration)
}