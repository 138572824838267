import styled from 'styled-components'
import { RPColors } from '../../../../styles/CSSConstant'

export const PriceContainer = styled.div``
export const PlanContainerSeparator = styled.div``
export const PlanContainerSeparatorMobile = styled.div``
export const PlanContainerDescription = styled.p``
export const PlanContainerDescriptionMobile = styled.div``
export const PlanContainerButton = styled.div``
export const PlanContainerButtonMobile = styled.div``
export const PlanContainerTopBar = styled.div``
export const Subscription = styled.div``
export const FeatureRow = styled.div``

export const TitlePage = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 7rem;
    align-items: center;
    justify-content: center;

    & .main-title {
        font-size: 32px;
        font-weight: bold;
        margin: 0;
    }

    & .success-content {
		font-size: 16px;
		text-align: center;

        & .success-subTitle {
			margin-bottom: 0;
        }
    }

	& a {
		font-size: 16px;
	}

    & .sub-title {
        font-size: 16px;
    }

    @media (max-width: 992px) {
        margin: 0 3rem;

		& .main-title {
			font-size: 26px;
			margin-top: 2rem;
		}
    }
`

export const PlanContainer = styled.div`
	width: 40%;
	height: 670px;
    margin: 3rem auto;
	padding: 0 0 5px;
	border: solid 1px #ddd;
	border-radius: 10px;
	background-color: #fafafa;
	display: flex;
	flex-direction: column;

	& > a {
		text-decoration: none;
	}

	& div.mobile-plan-container {
		height: 30px;
	}
	${PlanContainerTopBar} {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		text-align: center;
		width: 100%;
		height: auto;
		padding: 10px;
		margin: 0 0 12px;
		color: white;
		background-color: #333;
	}

	${Subscription} {
		width: 330px;
        height: auto;
        margin: 10px 20px 10px;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        color: #a08436;
	}

	${PlanContainerSeparator} {
		width: 100%;
		height: 1px;
		margin: 20px 0;
		background-color: #ddd;
	}

	${PlanContainerSeparatorMobile} {
		width: 100%;
		height: 1px;
		margin-top: 20px 10px;
		background-color: #ddd;
	}

	${PriceContainer} {
		width: 100%;
		height: 47px;
		margin: 10px 189px 10px 20px;
		font-family: Libre Franklin;
		font-size: 24px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #777;
		& > span {
			color: black;
			font-size: 40px;
			font-weight: bold;
		}
	}

	${PlanContainerDescription} {
		width: 90%;
		margin: 8px 20px 0px;
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #333;
	}

	${PlanContainerDescriptionMobile} {
		width: 90%;
		margin: 0px 20px 0px;
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #333;
	}

	${PlanContainerButton} {
		height: auto;
		width: fit-content;
		font-weight: bold;
		margin: auto;
		padding: 6px 25.5px;
		border-radius: 8px;
		border: solid 1px #ccc;
		background-color: #73333f;
		color: #ffffff;
		text-align: center;

		&:hover {
			background-color: #8E4250;
		}
	}

	${PlanContainerButtonMobile} {
		height: auto;
		width: auto;
		font-weight: bold;
		margin: 0px 16px 0;
		padding: 6px 25.5px;
		border-radius: 8px;
		border: solid 1px #ccc;
		background-color: #73333f;
		color: #ffffff;
		text-align: center;

		&:hover {
			background-color: #8E4250;
		}
	}

	@media (max-width: 1000px) {
		width: 60%;
	}

	@media (max-width: 768px) {
		width: 80%;
	}

	@media (max-width: 550px) {
		width: 85%;
	}
`

export const FeatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
	margin: 3rem auto;
	width: 85%;

    & p {
        font-weight: bold;
        font-size: 32px;
        margin-bottom: 0;
    }

    ${FeatureRow}{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 1.5rem;

        & img{
            width: 25px;
            height: 25px;
        }

        & p {
            font-weight: normal;
            font-size: 16px;
            line-height:1.5;
        }
    }

	@media (max-width: 1200px) {
		width: 82%;
	}

	@media (max-width: 992px) {
		width: 90%;
	}
`

export const FormGroup =styled.div`
    margin: auto;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    & .form-title{
        margin-top: 1.8rem;
        font-weight: bold;
        font-size: 18px;
    }

	& .react-datepicker-wrapper{
		width: 100%;
		& .react-datepicker__input-container{
			& input[type="text"]:focus{
				outline: none;
			}
		}
	}

	& .date-selection{
		position: relative;

		& svg{
			position: absolute;
			right: 5px;
			top: 8px;
		}
	}

	& .password-container{
		// display: flex;
        // flex-direction: column;
        // gap: 0.2rem;

        & .password{
            position: relative;

            & svg{
                position: absolute;
                right: 0;
                margin-right: 0.2rem;
                top: 1px;
            }
        }
	}

    @media(max-width: 992px) {
        max-width: 60%;
    }
    
    @media(max-width: 550px) {
        max-width: 85%;
    }
`

export const ValidatedField = styled.input`
	border: 1px solid black;
	border-radius: 8px;
	padding: 6px 6px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	&:focus {
		border-color: red;
		transition: border-color 0.3s ease-in-out;
		outline: 0;
	}
	&.error {
		border-color: #c50000;
	}
`

export const ValidatedFieldSelect = styled.select`
    // border: 1px solid black;
    border-radius: 8px;
    padding: 6px 6px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    &:focus {
        border-color: #ccc;
        transition: border-color 0.3s ease-in-out;
        outline: 0;
    }
    &.error {
        border-color: #c50000;
    }
`

export const ValidatedFieldTextField = styled.textarea`
	border-radius: 8px;
	padding: 6px 6px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	resize: none;
	&:focus {
		border-color: #ccc;
		transition: border-color 0.3s ease-in-out;
		outline: 0;
	}
	&.error {
		border-color: #c50000;
	}
`

export const FieldErrorMessage = styled.div`
    display: flex;
    flex-direction: row;
    color: #c50000;

    & .error-message {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        & img {
            width: 15px;
        }
    }
`

export const Separator = styled.hr`
    width: 100%;
    margin: 2rem auto;
    background-color: #ccc;
`

export const CreditCardContainer = styled.div``

export const GiftSubnContainer = styled.div`
    margin: auto;
    width: 50%;

    & .gift {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .gift-title {
            font-weight: bold;
            font-size: 20px;
        }
    }

    & .gift-content {
        display: flex;
		align-items: flex-start;
		justify-content: space-between;

        & div {
            display: flex;
			flex-direction: column;

			& div {
				display: flex;
				gap: 0.6rem;
				align-items: center;
				flex-direction: row;

				& input {
					padding: 0.2rem 0.5rem;
					width: 50%;
					outline: none;
					border-radius: 8px;
					border: 1px solid #ccc;
				}
			}
        }
    }

	& .gift-promo-code-container{

		& .gift-promo-code {
			display: flex;
			align-items: center;
			justify-content: space-between;

			& span > b {
				font-size: 1.2rem;
			}

			& div {
				display: flex;
				align-items: center;
				
				& input {
					width: 80%;
					outline: none;
					border-radius: 5px 0 0 5px;
					border: 1px solid #ccc;
					border-right: none;
					padding: 0.2rem 0.5rem;
				}

				& button {
					background-color: white;
					width: 5.5rem;
					border: 1px solid #ccc;
					font-weight: bold;
					padding: 0.2rem 0;
				}
			}
		}

		& .gift-error-message {
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}
	}

	

	& .gift-total {
		width: 100%;
		margin-bottom: 0;
		text-align: end;
		font-weight: bold;
		font-size: 1.2rem;
	}

	& ${CreditCardContainer} {
		display: flex;
		flex-direction: column;
		gap: 0.6rem;

		& .form-title {
			margin-top: 1.8rem;
			font-weight: bold;
			font-size: 18px;
		}

		& .credit-card-title.padding-bottom-20px{
			padding: 1.5rem;
			width: 100%;
			border: 1px solid #ccc;
			
			& div {
				& label, & input {
					cursor: pointer;
				}
			}
		}

		& .gift-credit-card {
			border: 1px solid #ccc;
			border-radius: 2px;

			& .credit-card-check {
				display: flex;
				align-items: center;
				gap: 0.6rem;
				padding: 1rem;
				border-bottom: 1px solid #ccc;
				margin: 0;
				& label {
					margin: 0;
				}

				& input {
					margin: 0;
				}
			}

			& .credit-card-content {
				padding: 1rem;
				display: flex;
				flex-direction: column;
				gap: 1.2rem;

				& .expiry-date-container {

					& .expiry-date-inputs {
						display: flex;
						align-items: center;
						justify-content: space-between;
					}
				}

				& .cvv {
					& .cvv-label {
						display: flex;
						align-items: center;
						justify-content: space-between;
					}
				}
			}

			& .credit-card-title {
				padding: 1rem;
				border-bottom: 1px solid #ccc;
			}
		}
	}

	& .checkbox {
		margin-left: 1.5rem;
		margin-top: 2rem;
		margin-bottom: 0;
		cursor: pointer;
			
		& label {
			padding: 0;
		}
	}

	& .captcha {
		margin-top: 2rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	& .gift-sub-detail {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 21px;
		align-items: flex-start;

		& div.detail-left {
			margin-left: 20px;
		}

		& div > span.normal-font {
			font-family: Libre Franklin;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: #333;
		}

		& div > span.bolded-font {
			font-family: Libre Franklin;
			font-size: 16px;
			font-weight: bold;
			font-stretch: normal;
			font-style: bold;
			line-height: 1.2;
			letter-spacing: normal;
			text-align: right;
			color: #333;
		}
	}

    @media (max-width: 992px) {
        width: 60%;
    }

    @media(max-width: 550px) {
        width: 85%;
    }
`

export const BackButton = styled.button`
	height: auto;
	padding: 0.5rem 1.5rem;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: #ffffff;
	&.mobile {
		padding-right: 12px !important;
	}
`
export const GiftBackButton = styled.button`
	width: 104px;
  	height: auto;
  	/* margin: 40px  0 0; */
  	padding: 6px 30px 6px 12px;
  	border-radius: 8px;
  	border: solid 1px #ccc;
  	background-color: #ffffff;
	&.mobile {
		padding-right: 12px !important;
	}
	& .img-arrow{
		transform: scaleX(-1);
	}
`

export const GiftButton = styled.button`
	width: fit-content;
  	height: auto;
  	padding: 6px 26px;
  	border-radius: 8px;
  	background-color: #73333f;
	font-family: Libre Franklin;
  	font-size: 14px;
  	font-weight: bold;
  	font-stretch: normal;
  	font-style: normal;
  	line-height: 1.2;
  	letter-spacing: normal;
  	text-align: center;
	color: #fff;
	border: none;

	&:hover {
		background-color: #8E4250;
	}
`

export const ActionButtons = styled.div<{$isAuthenticated?:boolean}>`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${props => props.$isAuthenticated &&`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 1.5rem;
		width: 100%;
	`}

	&.desktop {
		width: 50%;
		margin: auto;
	}
	
	&.mobile {
		/* width: 90%; */
		margin: 20px;
	}

`

export const PaymentMethodContainer = styled.div`

	&.desktop {
		width: 652px;
	}
	
	&.mobile {
		width: 80%;
	}

	& .back-button {
		padding-right: 25px !important;
		color: #333333 !important;
	}

	& div.payment-method {
		margin: 20px 0 0;
		border-radius: 8px;
		border: solid 1px #ddd;
		background-color: #ffffff;

		& .credit-card-title.padding-bottom-20px {
			& div {
				& label, & input {
					cursor: pointer;
				}
			}
		}
	}

	& div.credit-card-title {
		padding: 20px 20px 10px;
		font-family: Libre Franklin;
		font-size: 16px;  
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
	}

	& div.detail-left {
		margin: 10px 0px;
		display: flex;
		align-items: flex-start;

		&>span {
			width: 569px;
			margin: 0 0 0 8px;
			font-family: Libre Franklin;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.2;
			letter-spacing: normal;
			& .link{
				color: #a08436 !important;
			}
		}
		
	}

	& div.detail-right {
		display: flex;
		justify-content: space-between;
	}

	& .default {
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: bold;
		line-height: 1.2;
		color: #008185;
	}

	& .card {
		display: flex;
    	align-items: flex-start;
		padding-bottom: 15px;
	}

	& .card-details {
		padding-top: 5px;
		font-size: 14px;
	}

	& .padding-left-5px {
		padding-left: 5px;
	}

	& .margin-top-20px {
		margin-top: 20px !important;
	}

	& .margin-TB-0px {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
	}
	
	& .padding-bottom-20px {
		padding-bottom: 20px !important;
	}
`

export const SuccessActivationContainer = styled.div`
    margin: 0 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .info-container{
        display: flex;
        justify-content: start;
        flex-direction: column;

        & > h1{
            font-weight: 600;
            font-size: 35px;
            margin-bottom: 2rem;
        }
        
        & > p {
            margin-bottom: 0;
            font-size: 20px;
            font-weight: 500;
            max-width: 750px
        }
        
        & > a {
            font-size: 20px;
            margin: 0.5rem 0;
        }
    }

    @media (max-width: 1200px) {
        & > .info-container {
            margin-top: 2rem;
        }
    }

    @media (max-width: 768px) {
        margin: 0 2rem;
    }
`

export const GiftSubMainContainer = styled.div<{ isMobile: boolean }>`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Libre Franklin', sans-serif;

	& .gift-grid-container {
		display: flex;
		flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
		gap: 2rem;

		& .gift-grid {
			display: flex;
			flex-direction: column;
			gap: 2rem;

			& div {
				margin: 0;
			}
		}
	}
`

export const GiftSubTitle = styled.div<{ isMobile: boolean }>`
    width: 100%;
    display: flex;
    justify-content: center;
	marging-bottom: 2rem;
	margin-top: ${({ isMobile }) => isMobile ? '2rem' : 0};

    & p {
        font-size: 24px;
        font-weight: 700;
    }
`

export const GiftCardContainer = styled.div<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '100%' : '600px')};
    height: auto;
    margin: 32px ${({ isMobile }) => (isMobile ? 0 : '400px')} 204px;
    padding: 32px;
    padding-bottom: 32px;
    border-radius: ${({ isMobile }) => (isMobile ? 0 : '12px')};
    border: solid 1px ${RPColors.borderColor};
    background-color: 'white';
`

export const GiftCardFormBody = styled.div<{ isMobile: boolean }>`
    & .head-body {
        display: flex;
        flex-direction: column;
        gap: 8px;
		text-align: ${({ isMobile }) => isMobile ? 'center' : 'start'};

        & h3 {
            margin-bottom: 1rem;
            font-size: 20px;
            font-weight: 600;
        }

        & p {
            margin: 0;
            font-size: 16px;
        }
    }

	& .tier-description {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #333;
	}

	& .tier-select {
		margin-top: 24px !important;
	}

	& #tier-upgrade {
		padding: 18px 12px;
		border-radius: 3px;
		border: solid 1px #ccc;
		background-color: #fafafa;
	}

	& .perks-container {
		margin-top: 10px !important;
		font-style: italic;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 1.71;
		letter-spacing: normal;
		color: #333;
	}

	& .cc-cards {
		display: flex;
		gap: 0.7rem;
		align-items: center;
		margin-bottom: 1.5rem !important;
		margin-top: 1rem !important;
		justify-content: center;

		& img {
			width:  ${({ isMobile }) => isMobile ? '60px' : '70px'};
			height: auto;
		}
	}

    & .form {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        margin-bottom: 10px;

        & .first-input {
            width: 100%;
            display: flex;
            flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
            justify-content: space-between;
            align-items: start;
            gap: ${({ isMobile }) => (isMobile ? 0 : '16px')};

            & .password-input {
                width: 100%;
                position: relative;

                & img {
                    z-index: 0;
                    position: absolute;
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    right: 10px;
                    bottom: 15px;
                }
            }

			& span {
				font-weight: 500;
			}
        }

		& .profile-container {
			display: block;
			width: 100%
		}

		& .addon-input {
			border: 1px solid ${RPColors.borderColor};
			border-radius: 8px;
			padding: 1rem;
			margin-bottom: 1rem !important;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			

			& .addon-text {
				width: 100%;
				font-size: 16px;
				display: flex;
				align-items: start;
				justify-content: center;
				gap: 0.5rem;
				flex-direction: column;
			}
		}

		& .text-field {
			width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: start;
            gap: 16px;

			& span {
				font-weight: 800;
			}
		}

        & .action-input {
            margin: 0.7rem 0 1.5rem 0 !important;
            width: 100%;
            display: flex;
            flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
            justify-content: space-between;
            align-items: center;
            gap: 16px;

            & .recaptcha-style {
                text-align: ${({ isMobile }) => isMobile ? 'center' : 'start'};
            }
        }

		& actions {

		}
    }

	& .footer {
		text-align: center;

		& p {
			margin: 0;
		}
	}
`

export const MethodContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`

export const MethodCard = styled.div`
	border: 1px solid ${RPColors.borderColor};
	border-radius: 8px;
	display: flex;
	gap: 1rem;
	padding: 1.5rem;

	& img {
		width: 60px;
		height: auto;
	}

	& .content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		& span {
			font-size: 16px;
			font-weight: bold;
		}

		& a {
			font-size: 16px;
			font-weight: bold;
		}
	}


`