/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const MAX_RETRIES = 2

export const dataCySelector = (selectorName: string) => {
	if(window.location.hostname === 'www.robertparker.com') {
		return {}
	}
	return { 'data-cy': selectorName }
}

export const isDateExpired = (): boolean | undefined | null=> {
	if(window.location.hostname !== 'www.robertparker.com'){
		const storedExpirationDate = localStorage.getItem('AuthSessionExpiration')

		const expirationDate = storedExpirationDate ? storedExpirationDate : null

		if(!expirationDate)
			return true

		const expirationChecker = new Date() > new Date(expirationDate)

		return expirationChecker
	}

	return false
}

export const skipSearch = (wineSelectedId: string | null | undefined) => {
	if (window.location.hostname === 'www.robertparker.com' && wineSelectedId)
		return false

	if (window.location.hostname !== 'www.robertparker.com' && wineSelectedId) {
		if (!isDateExpired())
			return false
	}

	return true
}

export function isSubscriptionExpired(subscriptionEndDate: Date | string) {
	const subscriptionEnd = new Date(subscriptionEndDate)
	subscriptionEnd.setHours(23, 59, 0, 0)
	return subscriptionEnd < new Date()
}

