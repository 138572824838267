import React, { ReactElement, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { FEATURES } from '../../../../../configs'

import { isMobile } from 'react-device-detect'
import { Button, CardContainer, CardContent, FeaturedContainer, FeaturedContainerCommercial, FeaturedContainerPersonal, FeaturedWrapper, Seperator, SubscriptionCardContainer, SubscriptionHeader, SubscriptionSecondHeader, SubscriptionTypesContainer } from '../styles'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { FadeInAnimation } from '../../../../../styles/CSSTransition'
import TagManager from 'react-gtm-module'

const DesktopLayout = () => {

	const history = useHistory()

	useEffect(() => {
		TagManager.dataLayer({
			dataLayer: {
				ecommerce: null
			}
		})

		const datalayerEvent =  {
			dataLayer: {
				event: 'view_item_list',
				ecommerce: {
					currency: 'USD',
					item_list_id: 'rpwa_subscription_type',
					item_list_name: 'RPWA type of subscription',
					items: [
						{
							index: 0,
							item_brand: 'Personal subscription',
							item_name: 'Personal subscription'
						},
						{
							index: 1,
							item_brand: 'Commercial subscription',
							item_name: 'Commercial subscription'
						}
					]
				}
			}
		}
		// console.log('datalayerEvent: /subscriptions', datalayerEvent)

		TagManager.dataLayer(datalayerEvent)
	}, [])

	const onSubSelectionHandler = (type: string) => {

		TagManager.dataLayer({
			dataLayer: {
				ecommerce: null
			}
		})

		const dataLayer = {
			dataLayer: {
				event: 'select_item',
				ecommerce: {
					currency: 'USD',
					item_list_id: 'rpwa_subscription_type',
					item_list_name: 'RPWA type of subscription',
					items: [
						{
							item_name: type === 'personal' ? 'Personal subscription' : 'Commercial subscription',
							item_brand: type === 'personal' ? 'Personal subscription' : 'Commercial subscription'
						}
					]
				}
			}
		}

		// console.log('datalayerEvent: /subscriptions onclick', dataLayer)

		TagManager.dataLayer(dataLayer)
	}

	return (
		<div className="single-entry">
			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<div className="container">
					<SubscriptionHeader>What type of subscription are you looking for?</SubscriptionHeader>
				</div>
				<div className="container">
					<SubscriptionCardContainer>
						<SubscriptionTypesContainer>
							<Link to='/subscriptions/personal-offers' onClick={() => onSubSelectionHandler('personal')}>
								<CardContainer width="414px" height="258px">
									<CardContent isCenter={true}>
										<div className='img-container'>
											<img src='/img/icons/gold-wine.png' alt='goldwine' />
										</div>
										<h5>Personal</h5>
										<p>This is best for beginners and enthusiasts who’d like to expand their wine knowledge and get personal discounts to premier fine-wine tasting events.</p>
									</CardContent>
								</CardContainer>
							</Link>
							<Link to='/subscriptions/commercial-offers' onClick={() => onSubSelectionHandler('commercial')}>
								<CardContainer width="414px" height="258px">
									<CardContent isCenter={true}>
										<div className='img-container'>
											<img src='/img/icons/gold-three-wine.png' alt='goldthreewine' />
										</div>
										<h5>Commercial</h5>
										<p>This is best for sommeliers who also need the ability to use our logos, marketing collateral, and ratings stickers with their bottles/stores, as well as add up to
											40 teammates to their plan.</p>
									</CardContent>
								</CardContainer>
							</Link>
						</SubscriptionTypesContainer>

						<CardContainer width="860px" height="auto" withBackground={true}>
							<CardContent isCenter={false}>
								<h5>Have a redemption code?</h5>
								<div className='redeem-content-container'>
									<span className='redeem-sub'>If you have received a redemption code from our partners, please click on the button below to activate your free subscription.</span>
									<Button onClick={() => history.push('/redemption-codes/redeem')}>Reedem</Button>
								</div>
							</CardContent>
						</CardContainer>
					</SubscriptionCardContainer>

					<Seperator></Seperator>

					<div className="container">
						<SubscriptionSecondHeader>
							<h2>Still not sure which plan type is the best for you?</h2>
							<p>Learn more about the features to expect with your Robert Parker subscription.</p>
						</SubscriptionSecondHeader>
					</div>

				</div>
			</div>

			<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
				<div className="container">
					<FeaturedWrapper>
						<div className='p-flex'>
							<FeaturedContainer>&nbsp;</FeaturedContainer>
							<FeaturedContainerPersonal hasBorderBtm={true}><p>Personal</p></FeaturedContainerPersonal>
							<FeaturedContainerCommercial hasBorderRadius={true} hasBorderBtm={true}><p>Commercial</p> </FeaturedContainerCommercial>
						</div>
						{FEATURES.map((feature, key) => (
							<div className='p-flex' key={key}>
								<FeaturedContainer withBackground={key % 2 === 0 ? true : false} hasBorderBtm={key !== 8 ? true : false}>{feature.label}</FeaturedContainer>
								<FeaturedContainerPersonal withBackground={key % 2 === 0 ? true : false} hasBorderBtm={key !== 8 ? true : false}> {feature.left ? <p style={{ width: '18px' }}>&#10003;</p> : (<></>)}</FeaturedContainerPersonal>
								<FeaturedContainerCommercial withBackground={key % 2 === 0 ? true : false} hasBorderBtm={key !== 8 ? true : false}>{feature.right ? <p style={{ width: '18px' }}>&#10003;</p> : (<></>)}</FeaturedContainerCommercial>
							</div>
						))}
					</FeaturedWrapper>
				</div>
			</div>
		</div>
	)
}

const MobileLayout = () => {

	const history = useHistory()

	return (
		<div className="single-entry">
			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<div className="container">
					<SubscriptionHeader>What type of subscription are you looking for?</SubscriptionHeader>
				</div>
				<div className="container">
					<SubscriptionCardContainer>
						<SubscriptionTypesContainer>
							<Link to='/subscriptions/personal-offers'>
								<CardContainer width="414px" height="258px">
									<CardContent isCenter={true}>
										<div className='img-container'>
											<img src='/img/icons/gold-wine.png' alt='goldwine' />
										</div>
										<h5>Personal</h5>
										<p>This is best for beginners and enthusiasts who’d like to expand their wine knowledge and get personal discounts to premier fine-wine tasting events.</p>
									</CardContent>
								</CardContainer>
							</Link>
							<Link to='/subscriptions/commercial-offers'>
								<CardContainer width="414px" height="258px">
									<CardContent isCenter={true}>
										<div className='img-container'>
											<img src='/img/icons/gold-three-wine.png' alt='goldthreewine' />
										</div>
										<h5>Commercial</h5>
										<p>This is best for sommeliers who also need the ability to use our logos, marketing collateral, and ratings stickers with their bottles/stores, as well as add up to
											40 teammates to their plan.</p>
									</CardContent>
								</CardContainer>
							</Link>
						</SubscriptionTypesContainer>

						<CardContainer width="860px" height="auto" withBackground={true}>
							<CardContent isCenter={false}>
								<h5>Have a redemption code?</h5>
								<span>
									<p className='redeem-sub'>If you have received a redemption code from our partners, please click on the button below to activate your free subscription.</p>
									<Button onClick={() => history.push('/redemption-codes/redeem')}>Reedem</Button>
								</span>
							</CardContent>
						</CardContainer>
					</SubscriptionCardContainer>

					<Seperator></Seperator>

					<div className="container">
						<SubscriptionSecondHeader>
							<h2>Still not sure which plan type is the best for you?</h2>
							<p>Learn more about the features to expect with your Robert Parker subscription.</p>
						</SubscriptionSecondHeader>
					</div>
				</div>
			</div>

			<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
				<div className="container" style={{ paddingLeft: isMobile ? 0 : 15, paddingRight: isMobile ? 0 : 15 }}>
					<FeaturedWrapper isMobile={isMobile}>
						<div className='flex-featured-header'>
							<FeaturedContainerPersonal hasBorderBtm={true} hasBorderRadius={true}><p>Personal</p></FeaturedContainerPersonal>
							<FeaturedContainerCommercial hasBorderRadius={true} hasBorderBtm={true}><p>Commercial</p> </FeaturedContainerCommercial>
						</div>
						{FEATURES.map((feature, key) => (
							<div key={key}>
								<div className='flex-featured'>
									<FeaturedContainer withBackground={true} hasBorderBtm={true}>{feature.label}</FeaturedContainer>
									<div className='flex-featured-value'>
										<FeaturedContainerPersonal withBackground={false} hasBorderBtm={key !== 8 ? true : false} hasBorderRadius={false}> {feature.left ? <p style={{ width: '18px' }}>&#10003;</p> : (<></>)}</FeaturedContainerPersonal>
										<FeaturedContainerCommercial withBackground={false} hasBorderBtm={key !== 8 ? true : false}>{feature.right ? <p style={{ width: '18px' }}>&#10003;</p> : (<></>)}</FeaturedContainerCommercial>
									</div>
								</div>
							</div>
						))}
					</FeaturedWrapper>
				</div>
			</div>
		</div>
	)
}

function SubscriptionBuffer(): ReactElement {
	return (
		<CSSTransition
			in={true}
			appear={true}
			timeout={1000}
		>
			<FadeInAnimation>
				{isMobile ? <MobileLayout /> : <DesktopLayout />}
			</FadeInAnimation>
		</CSSTransition>
	)
}
export default SubscriptionBuffer