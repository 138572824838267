import React, { useEffect, useState, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// i18n
import Article from '../../../../structure/classes/Article'
import { TranslationsUtil } from '../../../../utils'
import englishTranslation from './translations/en.json'
import { SetActiveFilters } from '../../../../app/slices/SearchFilters'
import { CloudImageUtils } from '../../../../utils/CloudImageUtils'
// import useArticleTracking from '../../../../helper/hooks/useArticleTracking'

const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

function FeaturedArticle(props: { article: Article }): ReactElement {
	const { article } = props
	const translate = translationUtil.getTranslator()

	// const { triggerTrackingHandler } = useArticleTracking()

	const [authorName, setAuthorName] = useState('')
	const [authorId, setAuthorId] = useState('')
	const dispatch = useDispatch()

	useEffect(() => {
		if (article.author) {
			setAuthorName(article.author.name)
			setAuthorId(article.author.id)

		}
	}, [article])

	return (
		<article className="post--overlay post--overlay-bottom post--overlay-sm post--overlay-floorfade cat-4">
			<div className="background-img"><CloudImageUtils imgSrc={article.image?.url} alt="image" ratio={2.3} params=''/></div>
			<div className="post__text inverse-text">
				<div className="post__text-wrap">
					<div className="post__text-inner">
						<Link to={`/articles/${article.id}`} className="link-normal">
							<h6 className="post__title typescale-1">
								{article.title}
							</h6>
							<div className="post__excerpt">
								{article.excerpt}
							</div>
						</Link>
						<div className="post__meta post__meta--flex post__meta--border-top">
							<div className="post__meta-left">
								<span className="entry-author">{translate('By')}&nbsp;
									<Link to={`/author/${authorId}`} className="entry-author__name">{authorName}</Link>
								</span>
							</div>
							<div className="post__meta-right">
								<time className="time published" dateTime={article.published_date} title={article.getPublishedDate('title')}><i className="mdicon mdicon-schedule"></i>{article.getPublishedDate('display')}</time>
								<Link onClick={()=> dispatch(SetActiveFilters({}))} to={`/search/wine?article_id=${article.id}`} ><img src='/img/icons/black.png' />&nbsp;{article.tasting_note_count}</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <Link to={`/articles/${article.id}`} className="link-overlay"></Link> */}
			{/* <Link to={ '/article/categories' } className="post__cat post__cat--bg cat-theme-bg overlay-item--top-left">{ article.category.name }</Link> */}
			{/* <span className="post__cat post__cat--bg cat-theme-bg overlay-item--top-left">{ article.category.name }</span> */}
		</article>
	)
}

export default FeaturedArticle