import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit'

// Services
import {
	// AuthAPI,
	BridgeAPI,
	TastingNotesV2API,
	VintageChartV2API,
	AlgoliaSearchAPI,
	AlgoliaSearchAPIV2,
	WinePriceSearcherAPI,
	PreferenceAPI,
	ArticlesAPIv2,
	UsersAPIv2,
	PaymentMethodAPI,
	SubscriptionAPI,
	IssuesAPIv2,
	UserCompaniesAPIv2,
	CarouselAPI,
	UsersAPI,
	GiftSubsAPI,
	EditCompanyAPI,
	UserGroupAPI,
	SignUpAPI,
	RedeemSubscriptionAPI,
	AddOnsAPI
} from './services'

// Slices
import Users from './slices/Users'
import Authentication from './slices/Authentication'
import Producers from './slices/Producers'
import SearchFilters from './slices/SearchFilters'
import SearchResults from './slices/SearchResults'
import UserLocation from './slices/UserLocation'
import Payment from './slices/Payment'
import GiftSubscription from './slices/GiftSubscription'
import Issues from './slices/Issues'
import Analytics from './slices/Analytics'

import { persistReducer, persistStore, PERSIST } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['SearchResults', 'Analytics']
}

const rootReducer = {
	[ArticlesAPIv2.reducerPath]: ArticlesAPIv2.reducer,
	// [AuthAPI.reducerPath]: AuthAPI.reducer,
	[BridgeAPI.reducerPath]: BridgeAPI.reducer,
	[PreferenceAPI.reducerPath]: PreferenceAPI.reducer,
	[TastingNotesV2API.reducerPath]: TastingNotesV2API.reducer,
	[UsersAPIv2.reducerPath]: UsersAPIv2.reducer,
	[VintageChartV2API.reducerPath]: VintageChartV2API.reducer,
	[AlgoliaSearchAPI.reducerPath]: AlgoliaSearchAPI.reducer,
	[AlgoliaSearchAPIV2.reducerPath]: AlgoliaSearchAPIV2.reducer,
	[WinePriceSearcherAPI.reducerPath]: WinePriceSearcherAPI.reducer,
	[PaymentMethodAPI.reducerPath]: PaymentMethodAPI.reducer,
	[SubscriptionAPI.reducerPath]: SubscriptionAPI.reducer,
	[IssuesAPIv2.reducerPath]: IssuesAPIv2.reducer,
	[UserCompaniesAPIv2.reducerPath]: UserCompaniesAPIv2.reducer,
	[CarouselAPI.reducerPath]: CarouselAPI.reducer,
	[UsersAPI.reducerPath]: UsersAPI.reducer,
	[GiftSubsAPI.reducerPath]: GiftSubsAPI.reducer,
	[EditCompanyAPI.reducerPath]: EditCompanyAPI.reducer,
	[UserGroupAPI.reducerPath]: UserGroupAPI.reducer,
	[SignUpAPI.reducerPath]: SignUpAPI.reducer,
	[RedeemSubscriptionAPI.reducerPath]: RedeemSubscriptionAPI.reducer,
	[AddOnsAPI.reducerPath]: AddOnsAPI.reducer,
	Authentication,
	Producers,
	SearchFilters,
	SearchResults,
	Users,
	UserLocation,
	Payment,
	GiftSubscription,
	Issues,
	Analytics
}

const persistedReducer = persistReducer(persistConfig, combineReducers(rootReducer))

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [PERSIST],
			},
		})
			.concat(ArticlesAPIv2.middleware)
			// .concat(AuthAPI.middleware)
			.concat(BridgeAPI.middleware)
			.concat(PreferenceAPI.middleware)
			.concat(TastingNotesV2API.middleware)
			.concat(UsersAPIv2.middleware)
			.concat(VintageChartV2API.middleware)
			.concat(AlgoliaSearchAPI.middleware)
			.concat(AlgoliaSearchAPIV2.middleware)
			.concat(WinePriceSearcherAPI.middleware)
			.concat(PaymentMethodAPI.middleware)
			.concat(SubscriptionAPI.middleware)
			.concat(IssuesAPIv2.middleware)
			.concat(UserCompaniesAPIv2.middleware)
			.concat(CarouselAPI.middleware)
			.concat(UsersAPI.middleware)
			.concat(GiftSubsAPI.middleware)
			.concat(EditCompanyAPI.middleware)
			.concat(UserGroupAPI.middleware)
			.concat(SignUpAPI.middleware)
			.concat(RedeemSubscriptionAPI.middleware)
			.concat(AddOnsAPI.middleware)

})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>
